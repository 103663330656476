<template>
    <div class="vista-escritorio">
      <cabecera />
      <cuerpo>
        <!-- <div class="row video-explicativo py-5 mt-4 mr-0">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://www.youtube.com/embed/c7hzUYkooXY"
                  allowfullscreen
                ></b-embed>
              </div>
            </div>
          </div>
        </div> -->
  
        <div class="row definicion py-5 mt-4 mr-0" id="BaseDeDatos">
            <!-- <img src="@/assets/basededatos/fondo-definicion.svg" alt=""> -->
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 class="text-center mb-3">
                  Definición
                </h1>
  
                <h3>
                  - Base de Datos y utilidad -
                </h3>
  
                <div class="texto-definicion">
                  <p>
                    La <strong>Base de Datos</strong> se refiere al conjunto de información
                    perteneciente al <strong>Programa de Resultados Electorales Preliminares </strong> (PREP), ordenada de forma sistemática para su descarga y análisis. Es actualizada conforme al último corte. Permite realizar distintas operaciones ya que incluye datos númericos, alfanuméricos y cronológicos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row carpeta-archivos py-3 mt-4 mr-0" id="Contenido">
          <div class="container">
            <div class="row ">
              <div class="col-12">
                <h1 class="text-center mb-5">
                  Carpeta de archivos
                </h1>
              </div>
              <div class="col-6">
                <h3 class="mb-2">
                  - Contenido -
                </h3>
                <p class="mb-5">
                  Se trata de un archivo comprimido global con una nomenclatura que indica el <strong>año, mes, día</strong> y 
                  <strong> hora del corte</strong>; por ejemplo: <strong>20240602_2100_PREP</strong>, es de extensión 
                  <strong>.zip</strong> y se encuentra organizado en carpetas por tipo de elección.
                </p>
  
                <ul class="descripcion">
                  <li>
                    
                    <p>                    
                        20240602_2100_DIP_LOC.zip                    
                    </p>
  
                      <p>                     
                        20240602_2100_AYUN.zip                   
                      </p>
  
                     
  
                    <p>
                      Cada una de ellas contiene tres tipos de archivos:
                      los resultados y el catálogo de candidaturas ambos en formato <strong>.csv</strong>, por último,
                      el archivo con la descripción de las variables en formato <strong>.txt</strong>.
                    </p>
                  </li>
  
                  <li>
                    <p>
                      <strong>
                        Archivos de resultados:
                      </strong>
                    </p>
                    <p>
                       DIP_LOC_2024.csv                     
                    </p>
                    
  
                      <p>                     
                        AYUN_2024.csv                 
                      </p>
  
                    
  
                    <p>
                      Contiene los resultados de la votación por Acta a nivel estatal. Muestran el número de votos recibidos por cada uno de los
                      Partidos Políticos, combinaciones de coalición, Candidaturas Independientes, candidaturas no registradas y votos nulos.
                    </p>
                  </li>
  
                  <li>
                    <p>
                      <strong>
                        Catálogos de candidaturas:
                      </strong>
                    </p>       
                      
                      <p>                     
                        AYUN_CANDIDATURAS_2024.csv                 
                      </p>
                      <p>                     
                        DIP_CANDIDATURAS_2024.csv                 
                      </p>
  
  
                    <p>
                      Estos documentos contienen el listado de candidaturas por Partido Político y Candidaturas Independientes que 
                      participan en la elección estatal en cada uno de los cargos.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        Catálogos de candidaturas:
                      </strong>
                    </p>  
                    <p>
                      <strong>
                        LEEME.txt
                      </strong>
                    </p>
                    <p>
                      Describe a detalle cada uno de los campos que forman los archivos de resultados y candidaturas.
                    </p>
                  </li>
                  
                </ul>
                
              </div>
              <div class="col-6">
                <!-- <img src="@/assets/basededatos/carpetaarchivos.gif" class="img-fluid"  > -->
                <img src="@/assets/basededatos/carpeta-archivos.png" class="img-fluid"  >
  
              </div>
            </div>
          </div>
  
          <p class="nota col-10 ">
            *Nota:Recuerda, al realizar la descarga, el nombre del archivo .zip se complementará con <strong>.zip</strong>,
             comenzando por el <strong>año,mes</strong> y <strong>día</strong>, asi como la hora respecto al <strong>último corte</strong>,
             conservando siempre esta estructura: AAAAMMDD:HHMM_PREP.zip
          </p>
        </div>
  
        <div class="row descargar py-5 mt-4 mr-0" id="Descargar">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h2 class="mb-5">
                  - Descargar -
                </h2>
  
                <section class="grilla ">
                  <div class="contenido  ">
                    <h3 class="titulo1 ">
                      1
                      <span >
                        Descarga
                      </span>
                    </h3>
                    <div>                    
                      Haz clic en el botón Base de  Datos <br>  para iniciar la Descarga
                      del archivo comprimido.        
                    </div>
          
                  </div>
                  <!-- <div class="contenido">
                    <h3 class="titulo">
                      1
                      <span>
                        Descarga
                      </span>
                    </h3>
  
                    Haz clic en el botón Base de Datos para iniciar la Descarga
                    del archivo comprimido.
                  </div> -->
                  <div class="icono3">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h3 class="titulo1">
                      2
                      <span>
                        Localiza
                      </span>
                    </h3>
                    <div>
                      Encuentra el archivo en <br> la carpeta de descargas.
  
                    </div>
  
                  </div>
                  <div class="icono3">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h3 class="titulo1">
                      3
                      <span>
                        Selecciona                    
                      </span>
                    </h3>
                    <div>
                      El archivo, haciendo clic <br> sobre él.
                    </div>
                  </div>
  
                  <!-- <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4 class="titulo">
                      3
                      <span>
                        Selecciona
                      </span>
                    </h4>
  
                    Da clic sobre el archivo seleccionado.
                  </div> -->
                </section>
  
                <img src="@/assets/basededatos/descargar.png" alt="" class="img-fluid mx-auto d-block">
              </div>
            </div>
          </div>
        </div>
  
        <div class="row descomprimir py-5 mt-4 mr-0" id="Descomprimir">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h2 class="mb-5">
                  - Descomprimir -
                </h2>
  
                <section class="grilla">
                  <div class="contenido ">
                    <p class="titulo">
                      <big>1.</big> Sobre el archivo seleccionado, 
                      da clic derecho en <strong>extraer aquí</strong>.
                      <p>
                        En el caso del sistema operativo MacOS, sólo haz doble click sobre 
                        el archivo para descomprimir.
  
                      </p>
                    </p>
                  </div>
                  <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <p class="">
                      Se abrirá una ventana mostrando el proceso de extracción, los archivos automáticamente se descomprimirán en la misma carpeta. 
                      te aparecerán en la carpeta de descargas.
                    </p>
                  </div>
                  <!-- <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div> -->
                  <div class="contenido ml-3">
                    <p class="titulo">
                      <big>2.</big> Otra forma es dando <strong>clic derecho</strong> en el archivo,en el menú que se despliega busca 
                      la opción <strong>extraer ficheros</strong>.
                    </p>
                  </div>
                  <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <p class="">
                      Puedes <strong>seleccionar una carpeta</strong> existente o crear una nueva
                      para organizar el contenido descomprimido. Los archivos aparecerán en la carpeta
                      indicada.
                    </p>
                  </div>
                </section>
  
                <img src="@/assets/basededatos/Descomprimir.png" alt="" class="img-fluid mx-auto d-block mb-3">
  
                <p class="text-center tt-3">
                  Nota: Los sistemas operativos tienen herramientas integradas para la
                  desconmprensión de archivos, <br />
                  también pueden utilizar Winrar o 7-Zip en Windows y RAR en MacOS;
                  (Winzip no funciona).
                </p>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row abrir py-5 mt-4 mr-0" id="Abrir">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h2 class="mb-5">
                  - Abrir -
                </h2>
  
                <section class="grilla">
                  <div class="contenido">
                    <p class="titulo">
                      <strong>Da doble clic</strong> sobre el archivo que quieras abrir.
                    </p>
                  </div>
                  <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <p>
                      <strong>Los archivos .csv</strong> <br />
                      Contiene información organizada en filas y columnas. <br>
                      Se puede visualizar y trabajar con estos archivos en
                      programas de hojas de cálculo como Excel, Access, Calc Open Office, Smartsheet, entre otros.
                    </p>
                  </div>
                  <div class="icono">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <p class="titulo">
                      <strong>
                        Archivo LEEME.txt
                      </strong> <br />
                      Al hacer doble clic o seleccionar abrirlo, en el sistema operativo:
                      Bloc de notas en Windows o Notas en Mac.
                    </p>
                  </div>
                </section>
  
                <img src="@/assets/basededatos/abrir.png" alt="" class="img-fluid mx-auto d-block mb-3">
              </div>
            </div>
          </div>
        </div>
  
        <!-- <div class="row consulta py-5 mt-4 mr-0" id="FormatoCeldas">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h1 class="text-center mb-5">
                  Consulta
                </h1>
              </div>
              <div class="col-6">
                <h3 class="mb-2">
                  - Formato de celdas -
                </h3>
                <p class="mb-5">
                  Para una lectura correcta de las fechas y horas de corte, cambia el formato de celdas de las siguientes columnas a dd/mm/aaaa hh:mm
                </p>
  
                <ol class="descripcion">
                  <li>
                    Selecciona las columnas.
                  </li>
                  <li>
                    Da <strong>clic derecho</strong> en el mouse.
                  </li>
                  <li>
                    Selecciona "Formato de celdas".
                  </li>
                  <li>
                    Elige el formato "dd/mm/aaaa hh:mm" y <strong>Acepta.</strong>
                  </li>
                </ol>
              </div>
              <div class="col-6">
                <img src="@/assets/basededatos/consulta.gif" class="img-fluid">
              </div>
            </div>
          </div>
        </div> -->
  
        <div class="row consulta interpretacion-datos py-5 mt-4 mr-0" id="InterpretacionBaseDatos">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                  <div class="col-12">
                  <h1 class="text-center mb-5">
                    Consulta de datos
                  </h1>
                </div>
                <h1 class="mb-5">
                  - Interpretación de base de Datos -
                </h1>
  
                <div class="contenido mb-3">
                  <p>
                    Si deseas realizar una revisión detallada de las variables de la Base de Datos descarga <br />
                    el
                    <a href="#">
                      <u>
                        Manual Interpretación de la Base de Datos PREP, junio 2024.
                      </u>
                    </a>
                  </p>
                </div>
  
                <p>
                  A continuación te presentamos cuáles son los elementos que conforman el archivo de Base de Datos.
                </p>
  
                <div class="contenido mb-3">
                  <h4>
                    Fila 1
                  </h4>
                  <p class="font-weight-bold pl-3">
                Tipo de elección. Ya sea Diputaciones, Ayuntamientos 
                    <br>
                    y Otros cargos.
                  </p>
                </div>
  
                <div class="contenido mb-3">
                  <div class="grilla">
                    <div>
                      <h4>
                        Fila 2
                      </h4>
                      <p class="pl-3">
                        <strong>Fecha y hora del corte de información</strong> con el formato <strong>DD/MM/AAAA HH:MM.</strong> <br />
                        Se especifica la hora en formato de 24 h, Tiempo del Centro de México.
                      </p>
                    </div>
                    <div>
                      <img src="@/assets/basededatos/fila2.png" alt="" class="img-fluid d-block mx-auto">
                      <!-- <img src="@/assets/basededatos/fila2.svg" alt="" class="img-fluid d-block mx-auto"> -->
  
                    </div>
                  </div>
                </div>
  
                
  
                <div class="contenido mb-3">
                  <h4>
                    Fila 3
                  </h4>
                  <p class="font-weight-bold pl-3">
                    Nombre de las variables de las columnas de la Base de Datos.
                  </p>
                  <div class="contenido mb-3">
                  <div class="grilla">
                    <div>
                      <div class="d-flex">
                        <img src="@/assets/basededatos/fila4.svg" alt="" class="img-fluid mx-auto d-block">
                      <div class="icono">
                        <font-awesome-icon icon="angle-right" class="fa-8x" />
                      </div>
                      </div>
                      <div class="text-center">
                        <p>
                          Conoce más sobre estos términos y su significado en el 
                          <a href=""> Centro de Ayuda.</a>
                        </p>
                      </div>
                    </div>
                    <div class="ml-5 col-12">
                      
                      <ul>
                        <li>Actas esperadas</li>
                        <li>Actas registradas</li>
                        <li>Actas fuera de catálogo</li>
                        <li>Actas contabilizadas</li>
                        <li>Porcentaje de actas contabilizadas</li>                      
                        <li>Porcentaje de inconsistencias en actas</li>
                        <li>Actas no Contabilizadas</li>
                        <li>Lista Nominal de las Casillas con actas contabilizadas</li>
                        <li>Total de votos con Casillas Especiales</li>
                        <li>Total de votos sin Casillas Especiales</li>
                        <li>Porcentaje de Participación Ciudadana</li>
                      </ul>
                    </div>
                  </div>
                </div>
                  <h4>
                    Fila 4
                  </h4>
                  <p class="font-weight-bold pl-3">
                      Contenido de las variables del resumen de la Base de Datos correspondientes
                      <br>
                      a la fila 3.
                  </p>
                </div>
  
               
                <div class="contenido mb-3">
                  <h4>
                    Fila 5
                  </h4>
                  <p class="font-weight-bold pl-3">
                    Vacía para separar las variables de los datos de las Casillas.                
                  </p>
                </div>
                <div class="contenido ">
                  <h4>
                    Fila 6
                  </h4>
                  <p class="font-weight-bold pl-3">
                    Indica las variables de lso resultados de votación, las cuales se pueden agrupar en:
                  </p>
                </div>
  
                <div class="contenido mb-3">
                  <div class="grilla">
                    <div class="d-flex">
                      <img src="@/assets/basededatos/fila6.svg" alt="" class="img-fluid mx-auto d-block">
                      <div class="icono">
                        <font-awesome-icon icon="angle-right" class="fa-8x" />
                      </div>
                    </div>
                    <div class="ml-5 pl-3">
                      <ul>
                        <li>Clave Casilla</li>
                        <li>Clave acta </li>
                        <li>ID Entidad </li>
                        <li>Entidad </li>
                        <li>ID Distrito Local </li>
                        <li>Distrito Local </li>
                        <li>Sección </li>
                        <li>ID Casilla </li>
                        <li>Ext Contigua </li>
                        <li>Ubicación de la Casilla </li>
                        <li>Totales derivados de la votación </li>
                        <li>Resultados de la votación </li>
                        <li>Estatus, traslado y seguimiento de las actas </li>
                        <li>Procesamiento de las actas </li>
                        <li>Proceso de digitaliación </li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
  
                <div class="contenido mb-3">
                  <h4>
                    Fila 7
                  </h4>
                  <p class="font-weight-bold pl-3">
                    A partir de esta fila se indican los datos correspondientes a cada Acta
                  </p>
                </div>
                <div class="contenido mb-3">
                  <div class="grilla">
                    <div>
                      <img src="@/assets/basededatos/fila7.svg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row autenticidad py-5 mt-4 mr-0" id="Autenticidad">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h1 class="mb-5">
                  - Autenticidad -
                </h1>
                <p class="mb-5">
                  Para validar la autenticidad de la imagen del Acta de tu casilla, puedes utilizar un software lector de código único de imagen de la siguiente forma.
                </p>
  
                <section class="grilla-3">
                  <div class="contenido">
                    <h4>
                      Código HASH
                    </h4>
  
                    <ul>
                      <li>
                        Es una serie de caracteres únicos asociados al archivo de la imagen digitalizada del Acta de Escrutinio y Cómputo (AEC).
                      </li>
                    </ul>
                  </div>
                  <div class="icono2">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4>
                      Vérifica
                    </h4>
  
                    <ul>
                      <li>
                        Busca en interner algún lector de códigos para comprobar si la imagen del AEC
                        fue manipulada con editores de imágenes, o si se intentó alterar el Archivo
                        y la legitimidad de la información.
                      </li>
                      
                    </ul>
                  </div>
                  <div class="icono2">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4>
                      Valida
                    </h4>
  
                    <ul>
                      <li>
                       Una vez localizado o descargado el lector de código de integridad, selecciona la opción de lectura HASH 256,
                       ya que es el utilizado por el PREP para validad que el archivo no fue alterado.
                      </li>
                    </ul>
                  </div>
                </section>
  
                <img src="@/assets/basededatos/autenticidad-1.gif" alt="" class="img-fluid mx-auto d-block mb-5">
  
                <section class="grilla-3">
                  <div class="contenido">
                    <h4>
                      Ubica el Acta
                    </h4>
  
                    <ul>
                      <li>
                        Encuentra y descarga el acta de la Casilla que deseas verificar desde el PREP a tu computadora.
                      </li>
                    </ul>
                  </div>
                  <div class="icono2">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4>
                      Genera el Código HASH
                    </h4>
  
                    <ul>
                      <li>
                        Abre el lector del código de integridad HASH 256 de tu preferencia  y sube el archivo de la imagen del Acta.
                      </li>
                      <li>
                        Sigue la secuencia: File-Select File
                        (Archivo-Seleccionar archivo).
                      </li>
                      <li>
                        Selecciona la opción SHA256.
                      </li>
                      <li>
                        Elige el archivo y presiona abrir.
                      </li>
                    </ul>
                  </div>
                  <div class="icono2">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4>
                      Copia del código
                    </h4>
  
                    <ul>
                      <li>
                        Copia y pega el código que arroja el lector de código SHA y pégalo en la Base de Datos del PREP
                        al lado de la columna Código_integridad.
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
  
              <div class="col-6">
                <img src="@/assets/basededatos/autenticidad-2.svg" alt="" class="img-fluid d-block mx-auto p-5">
              </div>
              <div class="col-6">
                <img src="@/assets/basededatos/autenticidad-3.svg" alt="" class="img-fluid d-block mx-auto p-5">
              </div>
  
              <div class="col-12">
                <section class="grilla-2">
                  <div class="contenido">
                    <h4>
                      Compara
                    </h4>
  
                    <ul>
                      <li>
                        Compara el código de la imagen del acta con el proporcionado por el lector de código HASH 256.                    
                      </li>
                      <li>
                        Asegúrate de que todos los caracteres coincidan, independientemente de las mayúsculas o minúsculas.                  
                      </li>
                    </ul>
                  </div>
                  <div class="icono2">
                    <font-awesome-icon icon="angle-right" class="fa-8x" />
                  </div>
                  <div class="contenido">
                    <h4>
                      Confurna que no hay alteraciones
                    </h4>
  
                    <ul>
                      <li>
                        A través del Código HASH podrás identificar fácilmente si la imagen del Acta fue manipulada con algún software de edición de imágenes.
                      </li>
                    </ul>
                  </div>
                  
                    
                  
                </section>
                
              </div>
  
              <div class="col-6">
                <img src="@/assets/basededatos/autenticidad-4.svg" alt="" class="img-fluid d-block mx-auto p-5">
              </div>
              <div class="col-6">
                <img src="@/assets/basededatos/autenticidad-5.svg" alt="" class="img-fluid d-block mx-auto p-5">
              </div>
              <div class="text-justify col-11">
                <p>
                      Este proceso asegura que la imagen del acta de tu Casilla no haya sido modificada y garantiza la autenticidad de la información contenida en ella.
                      Sigue estos pasos para asegurarte de la integridad de los resultados electorales.
                </p>
              </div>
  
            </div>
          </div>
        </div>
  
        <div class="row catalogo-secciones py-5 mt-4 mr-0" id="Catalogo">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12">
                <h2 class="mb-5">
                  - Catálogo de secciones, distritos  y municipios -
                </h2>
  
                <section class="grilla"> 
                  <div class="contenido col-10 d-flex">
                    <p class="mt-5 pt-5 ">
                     
                      Este Catálogo muestra la lista aprobada de casillas del día
                      previo a la Jornada Electoral, con información actualizada
                      hasya las 20:00. Es importante destacar que no se realizarán
                      actualizaciones posteriores a ese corte de información.
                    </p>
                    <div class="">
                      <img src="@/assets/basededatos/Casillas.png" alt="" class="  d-block mb-3">
                    </div>
  
                  </div>
                 
                  
                </section>
  
              </div>
            </div>
          </div>
        </div>
  
        <div class="row carpeta-archivos py-3 mt-4 mr-0" id="Contenido">
          <div class="container">
            <h1 class="mb-2 mt-5">
              - Decripción del contenido de los archivos -
            </h1>
            <div class="row mt-5 ">
              
              <div class="col-6">
                  <p class="mb-5">
                    Dentro de este archivo <strong>.zip</strong>, encontrarás dos documentos:
                  </p>
                  <h4 class="mb-5">
                    DIP.csv
                  </h4>
  
                  <ul class="descripcion">
                    <li>   
                      <p>
                        Corresponde a los datos de identificación de las casillas aprobadas,
                        extraídos de la base de datos; cada línea corresponde a una casilla,
                        están separados por comas. En él se muestra la clave de cada casilla,
                        junto con la formación clave de su hubicación. Incluye datos de la
                        entidad, el distrito local, el municipio y la sección correspondiente.
                      </p>
                    </li>
                    <br>
                    <li>
                      <p>
                        <strong>
                          Los datos son los siguientes: <br>
                        </strong>
                        <p class="mt-2">
                          CLAVE_CASILLA | CLAVE_ACTA| 
                          ID_ENTIDAD| ENTIDAD|
                          ID_DISTRITO_LOCAL| DISTRITO_LOCAL|
                          SECCION| ID_CASILLA| TIPO_CASILLA|
                          EXT_CONTIGUA| UBICACION_CASILLA|
                          LISTA_NOMINAL
                        </p>
                      </p>                     
                  </li>
                </ul>
                
                <h4 class="mb-5">
                    LEEME.txt
                  </h4>
  
                  <ul class="descripcion">
                    <li>   
                      <p>
                        Describe a detalle cada uno de los campos que forman el archivo del catálogo.
                      </p>
                    </li>
                    <br>
                    
                </ul>
  
              </div>
              <div class="col-6 mt-5">
                <img src="@/assets/basededatos/descripcion-archivo.png" class="img-fluid"  >
              </div>
            </div>
          </div>
          <p class="nota col-10 ">
            Puedes seguir las mismas instrucciones de descarga, descomprension y apertura del archivo que para la base de datos. Si no las recuerdas, regresa a ellas.
            <br>
            <br>
            Este archivo se convierte en una herramienta para realizar análisis cruazdos entre datos locales y federales, lo que permite una comprensión 
            más completa  de la ubicación y contexto de tu casilla en el contexto político y geográfico.
          </p>
          <p class="col-12 mt-5 mb-5 text-center">
            Descarga el archivo .zip del Catálogo de secciones, distritos y municipios.
          </p>
          
        </div>
  
        <div class="text-center mt-3">
          <p>
            Los resultados presentados tienen un carácter informativo y no son definitivos, por tanto, no tienen efectos jurídicos.
          </p>
        </div>
      </cuerpo>
      <pie-de-pagina />
      <compartir />
    </div>
  </template>
  
  <script>
  import Cabecera from '@/components/Escritorio/componentes/Cabecera'
  import Cuerpo from '@/components/Escritorio/componentes/Cuerpo'
  import Compartir from '@/components/Compartir.vue'
  import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue'
  
  export default {
    name: 'BaseDatosEscritorio',
    components: {
      Cabecera,
      Cuerpo,
      Compartir,
      PieDePagina
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .video-explicativo {
    background-color:#582D73;
  }
  .catalogo-secciones{
    img{
      width: 400px;
      height: 400px;
      // margin-left: 150px;
    }
  
  }
  
  .definicion {
    .texto-definicion {
    //  background-image: url('@/assets/basededatos/fondo-definicion.svg');
      background-image: url('~@/assets/basededatos/fondo-definicion.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 100px 0;
  
      p {
        max-width: 500px;
        margin:  0 auto;
      }
    }
  }
  .icono2{
    svg{
      color:#C791A8
    }
  }
  .carpeta-archivos {
    background-color: #582D73;
    color: #f2f2f2;
  
    ul.descripcion {
      // list-style: initial;
      margin-left: 30px;
  
      li {
        margin-bottom: 50px;
      }
    }
    .nota{
     text-align: justify;
     font-size: 14px;
     margin-left: 140px;
    }
  }
  
  .descargar {
    .grilla {
      display: flex;
      grid-template-columns: 1fr 65px 1fr 65px 1fr 65px 1fr;
      gap: 15px;
      align-items: center;
      margin-bottom: 25px;
  
      .icono {
        svg {
          color: #C791A8;
        }
      }
      .titulo1{     
          font-size: 48px;      
        span{
          // padding-left: 5px;
          font-size: 28px;
        }
      }
      .icono3 {
        svg {
          color: #582D73;
          margin-left: 50px;
        }
      }
      .contenido {
        
          div{
            padding-left: 40px;
          }
        
        h4 {
          color: #582D73;
          line-height: 20px;
          margin-bottom: 30px;
  
          span {
            font-size: medium;
            margin-left: 10px;
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
  }
   .descomprimir {
    .grilla {
      display: grid;
      grid-template-columns: 2fr 70px 2fr  1.5fr 70px 1.8fr;
      gap: 15px;
      align-items: center;
      margin-bottom: 25px;
  
      .icono {
        svg {
          color: #C791A8;
        }
      }
      .titulo1{     
          font-size: 48px;      
        span{
          // padding-left: 5px;
          font-size: 28px;
        }
      }
      .icono3 {
        svg {
          color: #582D73;
          margin-left: 50px;
        }
      }
      .contenido {
        
          div{
            padding-left: 40px;
          }
        
        h4 {
          color: #582D73;
          line-height: 20px;
          margin-bottom: 30px;
  
          span {
            font-size: medium;
            margin-left: 10px;
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .descomprimir {
    background-color: #582D73;
    color: #f2f2f2;
  }
  
  .abrir {
    .grilla {
      display: grid;
      grid-template-columns: 1fr 75px 1.5fr 75px 1fr;
      gap: 15px;
      align-items: center;
      margin-bottom: 25px;
  
      .icono {
        svg {
          color: #582D73;
        }
      }
      .contenido {
        h4 {
          color: #582D73;
          line-height: 20px;
          margin-bottom: 30px;
  
          span {
            font-size: medium;
            margin-left: 10px;
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  .consulta {
    background-color: #B283B9;
    color: #000000;
  
    ol.descripcion {
      /* list-style: initial; */
      margin-left: 30px;
  
      li {
        margin-bottom: 45px;
      }
    }
  }
  
  .interpretacion-datos {
    svg{
      color:#86446D;
    }
    h1, h3 {
      color: #000000;
    }
  
    p {
      color: #000000;
    }
    .contenido {
      .grilla {
        display: grid;
        grid-template-columns: 1fr 300px;
        gap: 45px;
        align-items: center;
  
        ul {
          list-style: initial;
        }
      }
    }
  }
  
  .autenticidad {
    background-color: #582D73;
    color: #f2f2f2;
  
    .grilla-3 {
      display: grid;
      grid-template-columns: 1fr 65px 1fr 65px 1fr;
      gap: 30px;
  
      ul {
        list-style: initial;
  
        li {
          margin-left: 15px;
          margin-bottom: 30px;
        }
      }
    }
  
    .grilla-2 {
      display: grid;
      grid-template-columns: 1fr 65px 1fr;
      gap: 30px;
  
      ul {
        list-style: initial;
  
        li {
          margin-left: 15px;
          margin-bottom: 30px;
        }
      }
    }
  }
  </style>