<template>
  <div class="graficas-votos-por-candidatura">
    <div class="tabla">
      <ul>
        <li class="titulos">
          <div class="numeros-eje-y">
            <ul>
              <li ref="panelVotos">
                  <span>{{ obtenerEscala*4 }}</span>
              </li>
              <li>
                  <span>{{ obtenerEscala*3 }}</span>
              </li>
              <li>
                  <span>{{ obtenerEscala*2 }}</span>
              </li>
              <li>
                  <span>{{ obtenerEscala }}</span>
              </li>
              <li>
                  <span>0</span>
              </li>
            </ul>
          </div>
          <div class="texto">
            <ul>
              <li>
                <h2>Votos</h2>
                <h3>Total</h3>
              </li>
              <li>
                <h4>Porcentaje</h4>
              </li>
              <li>
                <h4>En la entidad</h4>
              </li>
              <li>
                <h4>En el Extranjero</h4>
              </li>
            </ul>
          </div>
        </li>
        <li v-for="(candidato, index) in votos.datos_candidatos" :key="index"
          :class="
            votos.candidato_id_con_mayor_numero_votos === candidato.candidato_id
              ? 'mayor'
              : ''
          "
        >
          <div>
            <div class="barra" :style="calcularEstiloElemento(candidato)"></div>
          </div>
          <div :class="{ 'divGober': esGober }">
            <div class="avatar-nombre" style="width:100px;">
              <div class="avatar">
                <img
                  :src="candidato.candidato_foto_url"
                  alt=""
                  style="width:50px;height:50px"
                />
              </div>
              <div class="nombres">
                <span>
                  {{ calcularNombreCandidato(candidato) }}
                </span>
              </div>
            </div>
            <div class="partidos">
              <ul>
                <li>
                  <div>
                    <img :src="candidato.candidatura_logo" alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="total-votos">
              <div class="votos-tabla">
                <ul>
                  <li>
                    <h3>
                      {{
                        candidato.candidatura_total_votos_general_numero
                      }}
                    </h3>
                  </li>
                  <li>
                    <h4>
                      {{
                        candidato.candidatura_total_votos_general_porcentaje
                      }}%
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {{
                        candidato.candidatura_total_votos_entidad_numero
                      }}
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {{
                        candidato.candidatura_total_votos_extranjero_numero
                      }}
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';

export default {
  name: 'GubernaturaGraficas',
  props: {
    votosProps: Object,
  },
  data() {
    return {
      votos: this.votosProps,
    };
  },
  computed: {
    obtenerMaximo() {
      const max = this.votos.datos_candidatos
        // eslint-disable-next-line eqeqeq
        .find((c) => c.candidato_id == this.votos.candidato_id_con_mayor_numero_votos);
      return typeof max !== 'undefined'
        ? numeral(max.candidatura_total_votos_general_numero).value()
        : 0;
    },
    obtenerEscala() {
      return Math.trunc((this.obtenerMaximo * 1.2) / 4);
      // return  0
    },
    esGober() {
      return this.$route.params.eleccion === 'G' && this.$route.name === 'VotosPorCandidaturas';
    },
  },
  methods: {
    calcularEstiloElemento(vCandidato) {
      const totalVotos = numeral(vCandidato.candidatura_total_votos_general_numero).value();
      const valH = (totalVotos * 140)
        / (this.obtenerMaximo * 1.2);
      return (
        `${'width: 56px;'
        + 'height:'}${
          String(valH)
        }px;`
        + `background-color:${
          vCandidato.candidatura_color
        };`
      );
    },
    calcularNombreCandidato(candidato) {
      return `${candidato.candidato_nombre} ${candidato.candidato_apellidos}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.nombres {
  span {
    font-size: 1vh !important;
    white-space: break-spaces;
  }
}

.divGober {
  height: 285px;
  .avatar-nombre {
    height: inherit;
  }
}
</style>
