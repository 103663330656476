<template>
  <div>
    <svg-map
      v-model="selectedLocation"
      :map="Municipios"
      style="width:100% !important; height:100%;"
      v-if="eleccion === 'A'"
    />
    <svg-map
      class="mapa-distritos"
      v-model="selectedLocation"
      :map="Distritos"
      style="width:100% !important; height:100%;"
      v-else
    />
  </div>
</template>

<script>
import { SvgMap } from 'vue-svg-map';
import Municipios from '@/assets/municipios';
import Distritos from '@/assets/distritos';
import 'vue-svg-map/dist/index.css';

export default {
  name: 'Mapa',
  components: {
    SvgMap,
  },
  props: ['esDiputaciones'],
  data() {
    return {
      selectedLocation: null,
      Municipios,
      Distritos,
      region: {},
    };
  },
  mounted() {
    this.cargarEventos();
  },
  methods: {
    registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
    cargarEventos() {
      const elemento = document.getElementsByClassName('svg-map__location');

      elemento.forEach((r) => {
        const regiones = {
          D: {
            id: 'distrito_id',
            datos: 'datos_distritos',
          },
          A: {
            id: 'municipio_id',
            datos: 'datos_municipios',
          },
        };
        const { eleccion } = this.$route.params;
        const region = regiones[eleccion];

        // eslint-disable-next-line no-param-reassign
        r.style.fill = this.regionSeleccionada[region.datos].filter((t) => {
          const regionId = parseInt(t[region.id], 10);
          return regionId === parseInt(r.getAttribute('finder'), 10);
        })[0]?.candidatura_ganadora_color;
      }, this);

      this.mapa = elemento;
    },
    desplegarRegion(e, r) {
      if (this.seleccionRegion) {
        this.mostrarRegion();
      }
      this.$nextTick(() => {
        this.region.estado = true;
        this.region.map = r;
        this.region.event = e;
        this.region.tipo = this.$route.params.eleccion;
        this.region.esDiputaciones = this.esDiputaciones;
        this.seleccionRegion = true;
        this.mapa.forEach((m) => {
          if (m.id !== r.id) {
            m.classList.add('opacidad_mapa');
          } else {
            m.classList.remove('opacidad_mapa');
          }
        });
      });
    },
    mostrarRegion() {
      this.seleccionRegion = false;
      this.mapa.forEach((m) => {
        m.classList.remove('opacidad_mapa');
      });
    },
    detalleMapa(r) {
      this.$router.push(
        `/${this.$route.params.eleccion}/DET/VPC/${r.getAttribute('finder')}`,
      );
      this.actualizarEstado(r);
      this.detalle.datos.elemento = r;
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.cargarEventos();
      });
    },
  },
  computed: {
    eleccion() {
      return this.$route.params.eleccion;
    },
    regionSeleccionada() {
      if (this.$store.state.cargaTerminada) {
        if (this.$route.params.eleccion === 'D') {
          return this.$store.state.dVotosMapaTarjetas;
        } if (this.$route.params.eleccion === 'A') {
          return this.$store.state.aVotosMapaTarjetas;
        }
      }

      return {};
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
