<template>
  <layout :extranjero="true">
    <div class="morado">
      <div class="mt-3 ml-4">
          <router-link to="/D/ENT/VPC">
            <font-awesome-icon icon="arrow-left" />          
            Regresar                          
          </router-link>     
      </div>
      <h1 class="ml-4 mt-3">Base de Datos</h1>
      
      
      <div v-if="!contenidoMostrado">
        <p class="ml-4 mt-2" >
          <a href="">
            Guía de descarga de archivos
          </a>
        </p>
      </div>

      <div v-if="contenidoMostrado">
        <div class="opciones">
          <select v-model="selectedOption">
            <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
      </div>

    </div>

    <div class="row contenedor " >
      <!-- CONTENEDOR PRINCIPAL -->
      <div class="col-12 col-lg-10" v-if="!contenidoMostrado">
        <div class="d-flex justify-content-center">
          <img src="@/assets/basededatos/bd.png" alt="">

        </div>
        <h5 class="font-medium pb-4 text-center pt-4">
          Base de Datos PREP
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div class="descripcion">              
              <p>
                La Base de Datos se refiere al conjunto de información
                perteneciente al Programa de Resultados Electorales Preliminares,
                ordenada de forma sistemática para su descarga y análisis.
                Es actualizada conforme al último corte. Permite realizar
                distrintas operaciones ya que incluye datos numéricos,
                alfanuméricos y cronológicos. En este documento exponemos la
                propuesta de estructura y copies para el apartado de base de 
                datos en el sitio del PREP.
              </p>
            </div>
            
          </div>
        </div>
        <div class="descargar2 d-flex justify-content-center mb-5">   
            <a
              class="boton-rosa"
              @click="descargarArchivo"
            >
              Descargar
              <font-awesome-icon icon="download"/>
            </a>
        </div>
        <div class="pl-5 mb-5">
            <a href="#" @click="mostrarContenido('definicion')"> <font-awesome-icon icon="chevron-right" /> Definición</a> <br>
            <a href="#" @click="mostrarContenido('carpeta')" > <font-awesome-icon icon="chevron-right" /> Carpeta de archivos</a> <br>
            <a href="#" @click="mostrarContenido('consulta')"> <font-awesome-icon icon="chevron-right" /> Consulta</a>
          </div>
      </div>
       
    </div>

    <div class="row contenedorGeneral  " v-show="contenidoMostrado">
       <!-- CONTENEDOR VARIANTE DEFINICION-->       
          <div v-if="contenido === 'definicion'">
            <div v-if="selectedOption === '1'">
              <div class="contenedorP">
                <h3 class="font-medium pb-4 text-center pt-4">
                - Definición -
                </h3>
                <div  class="definicion d-flex justify-content-center">
                  <img  src="@/assets/basededatos/bdmovil.svg" alt="">
                </div>
                <h4 class="font-medium pb-4 text-center pt-4">
                  - Base de Datos y utilidad -
                </h4>
                <p class="text-justify pl-4 pr-4 pb-5">
                  La <strong>Base de Datos</strong> se refiere al conjunto de información perteneciente el <strong>Programa de Resultados Electorales Preliminares</strong>
                  (PREP), ordenada de forma sistemática para su descarga y análisis. Es actualizada conforme al último corte. Permite realizar distintas operaciones ya que
                  incluye datos numéricos, alfanuméricos y cronológicos.
                </p>
              </div>
            </div>
          </div>

          <!-- CONTENEDOR VARIANTE CARPETA DE ARCHIVOS -->       
          <div v-else-if="contenido === 'carpeta'">
            <div v-if="selectedOption === '1'">
            <!-- Contenedor de contenido -->
              <div class="contenedorV">           
              <!--Primer contenedor -->
                <div class="mb-5">
                    <h3 class="font-medium pb-4 text-center pt-4">
                      - Contenido -
                    </h3>
                    <div  class="definicion d-flex justify-content-center mb-5">
                      <img  src="@/assets/basededatos/bdmovil.svg" alt="">
                    </div>
                  
                  <!-- Contenido en bloque morado -->
                    <div class="fondo">
                      <p class="text-justify pl-4 pr-4">
                        Se trata de un archivo comprimido global con una nomenclatura que indica el <strong>año, mes, día</strong>
                        y <strong>hora del corte</strong>; por ejemplo: <strong>20240602_2100_PREP</strong>, es extensión .zip
                        y se encuentra organizando en carpetas por tipo de elección:
                      </p>
                        <div class="pl-4">
                          <li>                  
                            20240602_2100_PREP_BCS.zip                    
                          </li>
                          <li>                  
                            20240602_2100_PREP_DIP_LOC.zip                  
                          </li>
                          <li>                   
                            20240602_2100_PREP_AYUN.zip                  
                          </li>                
                        </div>
                        <p class="text-justify pl-4 pr-4 pt-4">
                          Cada una de ellas contiene tres tipos de archivos: los resultados y el catálogo de
                          candidaturas ambos en formato <strong>.csv</strong>, por último, el archivo con la
                          descripción de las variables en formato <strong>.txt</strong>.
                        </p>
                        <div class="pl-4 pt-3">
                          <h5 class="pb-4">Archivos de resultados:</h5>
                          <li>                  
                            DIP_LOC_2024.zip                    
                          </li>
                          <li>                  
                            AYUN_2024.zip                  
                          </li>                                 
                        </div>
                        <p class="text-justify pl-4 pr-4 pt-4">
                          Contiene los <strong>resultados de la votación</strong> por acta a nivel estatal. muestran
                          el número de votos recibidos por cada uno de los Partidos Políticos, combinaciones de coalición, 
                          Candidaturas Independientes, candidaturas no registradas y votos nulos.
                        </p>
                        <div class="pl-4 pt-3">
                          <h5 class="pb-4">Catálogo de candidaturas:</h5>
                          <li>                  
                            DIP_LOC_CANDIDATURAS_2024.zip                    
                          </li>
                          <li>                  
                            AYUN_CANDIDATURAS_2024.zip                  
                          </li>                                 
                        </div>

                        <div class="pl-4 pt-4">
                          <h5 class="pb-2">Descripción de variables:</h5>                                                
                        </div>
                        <div class="pl-4 ">
                          <li>                  
                            LEEME.txt                   
                          </li>
                                                    
                        </div>
                        <p class="text-justify pl-4 pr-4 pt-4">
                          Describe a detalle cada uno de los campos que forman los archivos de resultadosy candidaturas.
                        </p>
                        <p class=" pl-4 pr-4">
                          <strong>*Nota:</strong> Recuerda, al realizar la decagra, el nombre del archivo .zip se complementará
                          con la fecha, comenzando por el año, mes y día, asi como la hora respecto al último corte, conservando
                          siempre esta estructura: <strong>AAAAMMDD_HHMM_PREP.zip</strong>
                        </p>



                    </div>            
                </div>
                
              </div>    
            <!--Contenedor Descarga -->
              <div class="contenedorV1">           
              <!--Primer contenedor -->
                <div class="">
                    <h3 class="font-medium  text-center pt-4">
                      - Descarga -
                    </h3>
                    <div  class="imagen d-flex justify-content-center ">
                      <img  src="@/assets/basededatos/descargaMovil.png" alt="">
                    </div>
                  
                  <!-- Contenido -->
                    <div class="descarga">
                      <div class="d-flex ml-4">
                          <h1> 1 </h1>                        
                          <div class="d-block">
                            <h5 class="pt-2 mt-1 ml-2">Descarga</h5>
                            <p class="text-justify  pr-5">
                            Toca el botón "ZIP" para comenzar la descarga del archivo comprimido.
                            </p>                       
                          </div>
                      </div>

                      <div  class="down d-flex justify-content-center">
                            <img src="@/assets/basededatos/down.svg" alt="">
                      </div>
                    </div>

                    <div class="descarga">
                      <div class="d-flex ml-4">
                          <h1> 2 </h1>                        
                          <div class="d-block">
                            <h5 class="pt-2 mt-1 ml-2">Localiza</h5>
                            <p class="text-justify  pr-5">
                            Encuentra el archivo en la carpeta de descargas de tu dispositivo móvil.
                            </p>                       
                          </div>
                      </div>

                      <div  class="down d-flex justify-content-center">
                            <img src="@/assets/basededatos/down.svg" alt="">
                      </div>
                    </div>

                    <div class="descarga">
                      <div class="d-flex ml-4">
                          <h1> 3 </h1>                        
                          <div class="d-block">
                            <h5 class="pt-2 mt-1 ml-2">Selecciona el archivo</h5>
                            <p class="text-justify  pr-5">
                            Toca el archivo para seleccionarlo.
                            </p>                       
                          </div>
                      </div>
                      
                    </div>

                </div>
                
              </div>    
           <!--Contenedor Descomprimir -->
           <div class="contenedorV2">           
              <!--Primer contenedor -->
                <div class="">
                    <h3 class="font-medium  text-center pt-4">
                      - Descomprimir -
                    </h3>
                    <div  class="imagen d-flex justify-content-center ">
                      <img  src="@/assets/basededatos/descomprimirM.png" alt="">
                    </div>
                  
                   <!-- Contenido en bloque morado -->
                   <div class="fondo">
                      <p class="text-justify pt-4 pl-4 pr-4">
                        En el caso de la mayoría de los dispositivos móviles, simplemente toca el archivo
                        seleccionado y busca la opción de <strong>"Descomprimir"</strong> en el menú
                        emergente. Si no encuentras esta opción, puedes utiizar aplicaciones de terceros
                        disponibles en la tienda de aplicaciones en la tienda de aplicaciones para Descomprimir
                        el archivo.
                      </p>
                  </div>
                    

                </div>
                
            </div> 
            
           <!--Contenedor Abrir -->
           <div class="contenedorV3">           
              <!--Primer contenedor -->
                <div class="mb-5">
                    <h3 class="font-medium  text-center pt-4">
                      - Abrir -
                    </h3>
                    <div  class="imagen d-flex justify-content-center ">
                      <img  src="@/assets/basededatos/abrirM.png" alt="">
                    </div>
                  
                   <!-- Contenido en bloque morado -->
                   <div class="pl-4">
                      <p class="">
                        <b>
                          Los archivos .csv
                        </b>
                      </p>

                      <p class="text-justify   pr-4">
                        Contienen información organizada en fila y columnas.
                        Puede visualizar y trabajar con estos archivos en aplicaciones de
                        hojas de cálculo disponibles para dispositivos móviles, como Excel,
                        Google Sheets, Numbers, etc.
                      </p>
                  </div>

                  <div  class="down d-flex justify-content-center">
                    <img src="@/assets/basededatos/down.svg" alt="">
                  </div>
                  <div class="pl-4 pb-4">
                      <p class="">
                        <b>
                          Archivo leeme.txt
                        </b>
                      </p>

                      <p class="text-justify   pr-4">
                        Al tocarlo, se abrirá la aplicación correspondiente según tu sistema
                        operativo móvil, por ejemplo, en Android se abrirá en una aplicación
                        de notas de textp y en iOS en la aplicación de Notas.
                      </p>
                  </div>

                </div>
                
            </div>  


           </div>
          </div>

          
          <!-- CONTENEDOR VARIANTE CONSULTA -->       
          <div v-else-if="contenido === 'consulta'">
            <div v-if="selectedOption === '1'">
            <div class="contenedorV4 ">
              <h4 class="font-medium pb-4 text-center pt-4">
              - Estructura de los datos -
              </h4>
                <div  class="definicion d-flex justify-content-center pl-5 pr-5">
                  <p>
                    Si deseas una revision detallada de las variables de la Base de Datos,
                    puedes descargar el Manual Intrepretación de la Base de Datos PREP,
                    actualizado en junio de 2024.
                  </p>
                </div>
              
              
                <p class="text-justify pl-4 pr-4 ">
                   Los elementos que conforman el archivo de la Base de Datos son:
                </p>

                <div class="d-flex justify-content-center  fila">
                  <img src="@/assets/basededatos/fila2.png" alt="">
                </div>
                <div class="pl-4">
                  <h5>Fila 1</h5>
                    <p>
                      Tipo de elección. Ya sea Ayuntamiento o Diputación Local.
                    </p>
                    <h5>Fila 2</h5>
                    <p>
                      <strong>Fecha y hora del corte de información</strong> con el formato <strong>DD/MM/AAA HH_MM.</strong>
                      La hora está en formeto 24 horas; Tiempo del Centro.
                    </p>
                    <h5>Fila 3</h5>
                    <p>
                      Nombre de las variables de las columnas de la Base de Datos.
                    </p>
                    <div class="d-flex justify-content-center  mb-2 fila2">
                      <img src="@/assets/basededatos/fila4.svg" alt="">
                    </div>
                    <p>
                      Recuerda que puedes conocer más sobre estos términos y su significado en el 
                      Centro de Ayuda.
                    </p>
                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down2.svg" alt="">
                    </div>
                    <div class="pl-3 pr-4">                      
                        <li> Actas esperadas </li>
                        <li> Actas registradas </li>
                        <li> Actas fuera de catálogo </li>
                        <li> Porcentaje de actas capturadas </li>
                        <li> Actas contabilizadas </li>
                        <li> Porcentaje de actas contabilizadas </li>
                        <li> Porcentaje de insconsistencias en actas </li>
                        <li> Actas no contabilzadas </li>
                        <li> Lista Nominal de las Casillas con actas contabilizadas </li>
                        <li> Total de votos con Casillas Especiales </li>
                        <li> Total de votos si Casillas Especiales </li>
                        <li> Porcentaje de Participación Ciudadana </li>
                                              
                    </div>
                    <h5 class="pt-4">Fila 4</h5>
                    <p>
                      Contenido de las variables del resumen de la Base de Datos correspondientes
                      a la fila 3.
                    </p> 
                    <h5>Fila 5</h5>
                    <p>
                      Vacía para separar las variables con los datos de las Casillas.
                    </p> 
                    <h5>Fila 6</h5>
                    <p>
                      Indica las variables de los resultados de votación, las cuales se pueden
                      agrupar en:
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down2.svg" alt="">
                    </div>
                    <div class="pl-3 pb-3">                      
                        <li> Clave Casilla</li>
                        <li> Clave Acta </li>
                        <li> ID Entidad </li>
                        <li> Entidad </li>
                        <li> ID Distrito Estatal </li>
                        <li> Sección </li>
                        <li> ID Casilla </li>
                        <li> Tipo Casilla </li>
                        <li> Ext contigua </li>
                        <li> Ubicación de la Casilla </li>
                        <li> Totales derivados de la votación </li>
                        <li> Estatus,  traslado y seguridad de las actas </li>
                        <li> Procesamiento de las actas </li>
                        <li> Proceso de digitalización </li>
                                              
                    </div>
                    <h5>Fila 7</h5>
                    <p>
                      Indica las variables de los resultados de votación, las cuales se pueden
                      agrupar en:
                    </p>
                    <div class="d-flex justify-content-center  mb-2  mr-3 fila7">
                      <img src="@/assets/basededatos/fila7.svg" alt="">
                    </div>
                </div>
             
            </div>
            <div class="contenedorV5 ">
              <h4 class="font-medium pb-4 text-center pt-4">
              - Autenticidad -
              </h4>
                <div  class="definicion text-justify pl-4 pr-4">
                  <p>
                    Para validar la autenticidad de la imagen del acta de tu Casilla, puedes utilizar
                    un programa informático de lector de código único de imagen de la siguiente forma.
                  </p>
                </div>

                <div class="d-flex justify-content-center  fila mb-5">
                  <img src="@/assets/basededatos/huella.svg" alt="">
                </div>
                <div class="pl-4">
                    <h5>Código HASH</h5>

                    <p >
                      <li class="pr-3 text-justify">
                        Es una serie de caracteres únicos asociadas al archivo de la imagen
                        digitalizada del Acta de Escrutinio y Cómputo (AEC).
                      </li>
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down3.svg" alt="">
                    </div>

                    <h5>Verifica imagen</h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Busca en internet algún lector de códigos
                        para comprobar si la imagen del AEC fue manipulada
                        con editores de imágenes, si se intentó alterar el archivo y
                        la legitimidad de la información.

                      </li>
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down3.svg" alt="">
                    </div>

                    <h5>Valida</h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Una vez localizado o descargado el lector de código de integridad,
                        selecciona la opción de lectura HASH 256, ya que es el utilizado
                        por el PREP para validar que el archivo no fue alterado.

                      </li>
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down3.svg" alt="">
                    </div>

                    <h5>Ubica el Acta </h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Encuentra y descarga el acta de la Casilla que deseas verificar
                        desde el PREP a tu computadora.

                      </li>
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down3.svg" alt="">
                    </div>

                    <h5>Genera el Código HASH </h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Abre el lector del código de integridad HASH 256 de tu preferencia
                        y sube el archivo de la imagen del acta.
                      </li>
                      <li class="pr-3 text-justify">
                        Elige la secuencia: File-Select File (Archivo-Seleccionar Archivo).
                      </li>
                      <li class="pr-3 text-justify">
                        Selecciona la opción HASH 256.
                      </li>
                      <li class="pr-3 text-justify">
                        Elige el archivo y presiona abrir.
                      </li>
                    </p>

                    <div class="d-flex justify-content-center  mb-2 ">
                      <img src="@/assets/basededatos/down3.svg" alt="">
                    </div>

                    <h5>Copia del código </h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Copia y pega el código que arroja el lector de código SHA y pégalo
                        en la Base de Datos del PREP al lado de la columna Código_integridad.
                      </li>                   
                    </p>

                    <div class="d-flex justify-content-center hash  mb-5">
                      <img src="@/assets/basededatos/autenticidad-4.svg" alt="">
                    </div>

                    <h5>Compara </h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        Compara el código de la imagen del acta con el proporcionado por el lector
                        de código HASH 256.
                        <br>
                        <br>                        
                        Asegúrate de que todos los caracteres coincidan, independientemente de
                        las mayúsculas o minúsculas.
                      
                      </li>                   
                    </p>
                    <div class="d-flex justify-content-center hash  mb-5">
                      <img src="@/assets/basededatos/autenticidad-3.svg" alt="">
                    </div>
                    <h5>Confirma que no hay alteraciones </h5>
                    
                    <p >
                      <li class="pr-3 text-justify">
                        A través del código HASH 256 podrás identificar fácilmente si la imagen
                        del acta fue manipulada con algún software de edición de imágenes.
                        
                      </li>                   
                    </p>

                    <div class="d-flex justify-content-center hash  mb-5">
                      <img src="@/assets/basededatos/verificacion.svg" alt="">
                    </div>
                    
                    <p class=" pl-1 pr-4  pb-5 text-justify"> 
                      Este proceso asegura que la imagen del acta de tu Casilla no haya sido 
                      modificada y garantiza la autenticidad de la información contenida en ella.
                      Sigue estos pasos para asegurarte de la integridad de los resultados 
                      electorales.
                    </p>
                   
                   
                   
                   
                    
                 
                </div>
             
            </div>

            </div>
          </div>
        
    </div>

    <div class="row contenedor2 ">
      <div class="col-12 col-lg-10 " v-if="!contenidoMostrado">
        <div class="d-flex justify-content-center">
          <img src="@/assets/basededatos/carpeta.png" alt="">

        </div>
        <h5 class="font-medium pb-4 text-center pt-4">
          Catálogo de secciones,
          distritos y municipios
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div class="descripcion">              
              <p>
                Este Catálogo muestra la lista aprobada
                de casillas del día previo a la Jornada
                Electoral, con información actualizada 
                hasta las 20:00. Es importante destacar
                que no se realizarán actualizaciones posteriores
                a ese corte de información.
              </p>
            </div>
            
          </div>
        </div>
        <div class="descargar2 d-flex justify-content-center mb-5">   
            <a
              class="boton-rosa"
              @click="descargarArchivo"
            >
              Descargar
              <font-awesome-icon icon="download"/>
            </a>
        </div>
        <div class="pl-3 mb-5">
          <a href="#" @click="mostrarContenido('contenido')"> <font-awesome-icon icon="chevron-right" /> Descripción del contenido de los archivos</a> <br>
        </div>
      </div>

    </div> 
    <div v-if="contenido === 'contenido'">
            <!-- Contenedor de contenido -->
            <div class="contenedorV6">           
              <!--Primer contenedor -->
                <div class="mb-5">
                    <h5 class="font-medium pb-4 text-center pt-4">
                      - Descripción del contenido de los archivos -
                    </h5>
                    <div  class="definicion d-flex justify-content-center mb-5">
                      <img  src="@/assets/basededatos/bdmovil.svg" alt="">
                    </div>
                  
                  <!-- Contenido en bloque morado -->
                    <div class="fondo">
                      <p class="text-justify pl-4 pr-4">
                       Dentro de este archivo .zip, encontrarás dos documentos:
                      </p>
                        <div class="pl-4">
                          <li>                  
                              DIP_BCS.zip                    
                          </li>                                         
                        </div>
                        <p class="text-justify pl-4 pr-4 pt-4">
                          Corresponde a los datos de identificación de las casillas aprobadas,
                          extraídos de la base de datos; cada línea corresponde a una casilla,
                          están separados por comas. En él se muestra la clave de cada casilla,
                          junto con la información clabe de su ubicación. Incluye datos de la entidad,
                          el distrito local, el municipio y la sección correspondiente.
                        </p>                    
                      
                        <p class="pl-4">
                          Los datos son los siguientes:
                        
                        CLAVE_CASILLA| ID_ENTIDAD| NOMBRE_ENTIDAD| 
                        ID_DISTRITO_ESTATAL| NOMBRE_DISTRITO_ESTATAL| 
                        ID_MUNICIPIO| NOMBRE_MUNICIPIO| SECCION | ID_CASILLA |
                        TIPO_CASILLA| LISTA_NOMINAK

                       </p>


                        <div class="pl-4 ">
                          <li>                  
                            LEEME.txt                   
                          </li>
                                                    
                        </div>
                        <p class="text-justify pl-4 pr-4 pt-4">
                          Describe a detalle cada uno de los campos que forman los archivos de resultadosy candidaturas.
                        </p>
                        <p class=" pl-4 pr-4">
                          <strong>*Nota:</strong> Recuerda, al realizar la decagra, el nombre del archivo .zip se complementará
                          con la fecha, comenzando por el año, mes y día, asi como la hora respecto al último corte, conservando
                          siempre esta estructura: <strong>AAAAMMDD_HHMM_PREP.zip</strong>
                        </p>



                    </div>            
                </div>
                
              </div>    
           
          
          


      </div>
     
  </layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout.vue'

export default {
  name: 'BaseDatosMovil',
  components: {
    Layout
  },
  data() {
    return {
      contenido: '', 
      contenidoMostrado: false, 
      selectedOption: '1',
      options: [
        { value: '1', label: 'Definición ' },
        { value: '2', label: 'Carpeta de archivos' },
        { value: '3', label: 'Consulta' },
      ]
    };
  },
  methods: {
    mostrarContenido(opcion) {
      this.contenidoMostrado = true;
      this.contenido = opcion  ;
    },
    descargarArchivo() {      
      const rutaArchivo = "/bdatos/20240125_1900_PREP_BCS.zip";
      // Crear un elemento enlace temporal
      const link = document.createElement("a");
      link.href = rutaArchivo;
      link.download = "20240125_1900_PREP_BCS.zip"; 
      // Simular un clic en el enlace para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      // Eliminar el elemento enlace temporal
      document.body.removeChild(link);
    },
  }
}
</script>

<style lang="scss" scoped>
body {
  margin: 0; 
  padding: 0; 

}

.contenedorGeneral{
  .contenedorP{
  background-color: #F4F4F4;
  border-radius: 10px;
  // margin-top: 0px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    // align-items: center;
    height: 80px;
    margin-top: 25px;
  }
}
.contenedorV{
  background-color: #F4F4F4;
  border-radius: 10px;
  // margin-top: 200px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  .fondo{
    background-color: #582D73;
    border-radius: 0 0 10px 10px;
    margin-bottom: 200px;
    padding: 20px 0;
    color: #f2f2f2;
  }
  

  
  
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    height: 80px;
    margin-top: 25px;
  }
}
.contenedorV1{
  background-color: #F4F4F4;
  border-radius: 10px;
  margin-top: -180px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  .imagen{
    img{
      align-items: center;
      width: 300px;
      height: 400px;
      margin-top: 0;
    }
  }
  .descarga{
    .down{
      img{
        height: 80px;
        width: 90px;
      }
  }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    height: 80px;
    margin-top: 25px;
  }
}
.contenedorV2{
  background-color: #F4F4F4;
  border-radius: 10px;
  // margin-top: -50px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  .fondo{
    background-color: #582D73;
    border-radius: 0 0 10px 10px;
    margin-bottom: 200px;
    padding: 20px 0;
    color: #f2f2f2;
  }
  .imagen{
    img{
      align-items: center;
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
  .descarga{
    .down{
      img{
        height: 80px;
        width: 90px;
      }
  }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    height: 80px;
    margin-top: 25px;
  }
}
.contenedorV3{
  background-color: #F4F4F4;
  border-radius: 10px;
  margin-top: -180px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  .fondo{
    background-color: #582D73;
    border-radius: 0 0 10px 10px;
    margin-bottom: 200px;
    padding: 20px 0;
    color: #f2f2f2;
  }
  .imagen{
    img{
      align-items: center;
      width: 290px;
      height: 120px;
      margin-bottom: 50px;
    }
  }
  
    .down{
      img{
        height: 70px;
        width: 80px;
        margin-top: -5px;
        margin-bottom: 30px;
      }
  }
  
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    height: 80px;
    margin-top: 25px;
  }
}
.contenedorV4{
  background-color: #B283B9;
  border: none;
  border-radius: 10px;
  // margin-top: 0px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  .fila{
    img{
      height: 120px;
      width: 300px;
  }
  }
  .fila2{
    img{
      width:300px;
    }
    
  }
  .fila7{
    img{
      margin-bottom: 40px;
      // width:300px;
    }
    
  }
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
 
}
.contenedorV5{
  background-color: #582D73;
  border: none;
  border-radius: 10px;
  color: #F4F4F4;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  .fila{
    img{
      height: 120px;
      // width: 100%;
      height: 90%;
  }
  }
  .fila2{
    img{
      width:300px;
    }
    
  }
  .hash{
    img{
      width:200px;
    }
    
  }
  .fila7{
    img{
      margin-bottom: 40px;
    }
    
  }
  
 .descripcion{
  p{
    font-size: 14px;
  }
 }
 
}


}

.contenedor{
  background-color: #F4F4F4;
  border-radius: 10px;
  margin-top: 150px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    // align-items: center;
    height: 80px;
    margin-top: 25px;
  }
}
.contenedor2{
  background-color: #F4F4F4;
  border-radius: 10px;
  // margin-top: 200px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
 


}

.contenedorV6{
  background-color: #F4F4F4;
  border-radius: 10px;
  // margin-top: -50px;
  margin-left: 40px;
  margin-right: 40px;
  // margin-bottom: 20px;
  position: relative;
  z-index: 2;
  .fondo{
    background-color: #582D73;
    border-radius: 0 0 10px 10px;
    margin-bottom: 200px;
    padding: 20px 0;
    color: #f2f2f2;
  }
  
 .descripcion{
  p{
    font-size: 14px;
  }
 }
 
}

.definicion{
      // background-image: url('~@/assets/basededatos/bdmovil.svg');
      padding: 20px 0;
      img{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 150px;
        width: 200px;
      }
      // padding: 100px 0;
}


.morado {
  background-color:#582D73;
  color: #f2f2f2;
  // height: 100px;
  width: 100%;
  height: 250px;
  position: absolute;
  z-index: 1;
  .opciones{
      display: flex;
      justify-content: center;

      select{
        width: 350px;
        height: 40px;
        border-radius: 10px;
        
      }
  }
}
h1 {
  margin-top: 0; 
}
</style>
