import { ref, computed } from '@vue/composition-api';
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

function useDetalleEleccion(props, context) {
  const seen = ref(false);
  const random = ref(Math.random());

  // console.log(props.votos);

  const seleccionados = computed(() => {
    if (
      props.votos.datos_candidatos
    ) {
      return props.votos?.datos_candidatos.filter(
        (candidato) => candidato.seleccionado,
      );
    }

    if (
      props.votos.datos_candidaturas
    ) {
      return props.votos?.datos_candidaturas.filter(
        (candidato) => candidato.seleccionado,
      );
    }

    return [];
  });

  const noSeleccionados = computed(() => {
    if (
      props.votos.datos_candidatos
    ) {
      return props.votos?.datos_candidatos.filter(
        (candidato) => !candidato.seleccionado,
      );
    }

    if (
      props.votos.datos_candidaturas
    ) {
      return props.votos?.datos_candidaturas.filter(
        (candidato) => !candidato.seleccionado,
      );
    }

    return [];
  });

  const colores = computed(() => obtenerColoresDeAvatar(context));

  const tresSeleccionados = computed(() => seleccionados.value.length === 3);

  const anchoPantalla = computed(() => context.$store.state.anchoPantalla);

  function cerrarModal() {
    context.$bvModal.hide('DetalleEleccionModal');
  }

  // console.log(context.$router.currentRoute.name);

  return {
    seen,
    seleccionados,
    noSeleccionados,
    colores,
    tresSeleccionados,
    anchoPantalla,
    cerrarModal,
    random,
  };
}

export default useDetalleEleccion;
