<template>
  <div>
    <div class="tarjetas votos-por-candidatura">
      <ul>
        <li
          v-for="(candidato, index) in votos.datos_candidatos"
          :key="index"
          :class="
            votos.candidato_id_con_mayor_numero_votos ==
            candidato.candidato_id
              ? 'mayor'
              : ''
          "
        >
          <div>
            <div class="avatar-nombre">
              <div class="avatar">
                <img :src="candidato.candidato_foto_url" alt="" />
              </div>
              <div class="nombres">
                <span>{{ candidato.candidato_nombre }}</span>
                <span>{{ candidato.candidato_apellidos }}</span>
              </div>
            </div>
            <div class="partidos">
              <ul>
                <li
                  v-for="(partido, index) in candidato.candidatura_votos_desglosados"
                  :key="index"
                >
                  <div>
                    <img :src="partido.logo_integrante" alt="" />
                    <span>{{partido.votos_integrante}}</span>
                    <font-awesome-icon icon="plus-circle"/>
                  </div>
                </li>
              </ul> 
            </div>
            <div class="total-votos">
              <div class="total-de-votos">
                <h3>Total de votos</h3>
                <div>
                  <span>
                    <strong>{{
                      candidato.candidatura_total_votos_general_numero
                    }}</strong>
                  </span>
                  <span>{{
                    candidato.candidatura_total_votos_general_porcentaje
                  }}%</span>
                </div>
              </div>
              <div class="votos-disponibles">
                <h3>Votos</h3>
                <h4>En la Entidad</h4>
                <div>
                  <span>
                    <strong>{{
                      candidato.candidatura_total_votos_entidad_numero
                    }}</strong>
                  </span>
                  <span>{{
                    candidato.candidatura_total_votos_entidad_porcentaje
                  }}</span>
                </div>
                <h4>En el extranjero</h4>
                <div>
                  <span>
                    <strong>{{
                      candidato.candidatura_total_votos_extranjero_numero
                    }}</strong>
                  </span>
                  <span>{{
                    candidato.candidatura_total_votos_extranjero_porcentaje
                  }}</span>
                </div>
              </div>
            </div>
            <b-form-checkbox
              class="boton-seleccionar seleccionado"
              button
              v-model="candidato.seleccionado"
              v-if="candidato.seleccionado"
            >
              Quitar selección
            </b-form-checkbox>
            <b-form-checkbox
              class="boton-seleccionar"
              button
              v-model="candidato.seleccionado"
              v-else
              :disabled="tresSeleccionados"
            >
              Seleccionar
            </b-form-checkbox>
          </div>
        </li>
      </ul>
    </div>

    <div class="resumen">
      <div class="info">
        <p>
          <font-awesome-icon icon="info-circle"/> Da clic en
          <strong>Seleccionar</strong> para visualizar el detalle de la
          votación. Puedes seleccionar hasta tres opciones.
        </p>
        <button
          class="boton-azul"
          v-b-modal.DetalleEleccionCandidaturasModal
          :disabled="!alMenosUnSeleccionado"
        >
          Ver detalle
        </button>
      </div>
    </div>

    <DetalleEleccionCandidaturas
      :resumen="gubernaturaResumenNivelEntidad"
      :votos="votos"
      :esCandidato="true"
    />
  </div>
</template>

<script>
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';

export default {
  name: 'GubernaturaTarjetas',
  components: {
    DetalleEleccionCandidaturas,
  },
  props: {
    votosProps: Object,
  },
  data() {
    return {
      votos: this.votosProps,
    };
  },
  computed: {
    tresSeleccionados() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados === 3;
    },
    gubernaturaResumenNivelEntidad() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }
      return this.$store.state.gResumenNivelEntidad;
    },
    alMenosUnSeleccionado() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados >= 1;
    },
  },
};
</script>
