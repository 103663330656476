<template>
  <layout :extranjero="true">
   
    <div class="morado mb-5 ">
      <div class="mt-4 ml-4">
        <router-link to="/D/ENT/VPC">
          <font-awesome-icon icon="arrow-left" />          
          Regresar                          
        </router-link>      
      </div>
      <div  class="pt-2 text-center mt-2">
        <h2>
          Preguntas Frecuentes
        </h2>
      </div>
      <div class="opciones  ">
        <select v-model="selectedOption">
          <!-- <option  value="" disabled hidden>Categoría</option> -->
          <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>

    <div class="row contenedor " v-if="selectedOption === '1'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Avance Nacional -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Qué muestra el Avance Nacional?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Muestra el porcentaje de la Participación ciudadana que
                hasta el momento ha votado; el número y porcentaje de Actas
                capturadas al momento y la hora del último corte, éste se
                actualiza aproximadamente cada 15 minutos.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué indica el porcentaje de las Actas capturadas?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica el porcentaje de Actas que hasta el momento se han
                ingresado al PREP.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué indica el Último corte y cada cuando se realiza?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica el corte de información que realiza el PREP, éste se
                actualiza aproximadamente cada 15 minutos.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué indica el porcentaje de la Participación ciudadana?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica los votos que hasta el momento se han registrado con
                base en la Lista Nominal de personas con derecho a votar.
              </p>
            </div>            
          </div>
        </div>
      </div>
    </div>

    <div class="row contenedor2 " v-if="selectedOption === '2'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Consulta de la votación -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo buscar los resultados en el PREP?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Los reportes de resultados puedes consultarlos a través de los 
                combos principales: Votos por Distritos y Votos por Partido Político
                y Candidatura Independiente. Asímismo tendrás la opción de detallar 
                tu búsqueda a través de las opciones: Circunscripción, Entidad, Distrito y Sección. 
                También podrás consultar los resultados de tu casilla, indicando tu Entidad, Sección 
                y Clave de elector.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Por qué algunas veces las opciones de búsqueda están deshabilitadas?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Sólo se activarán estas opciones cuando hayas seleccionado un nivel 
                más alto, cuando lo hagas te permitirá escoger la información. 
                Por ejemplo: Si seleccionas la Circunscripción 4, se habilitará la 
                opción Entidad, que te permitirá ver los estados que comprenden dicha
                Circunscripción.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se muestran en Detalle por Circunscripción?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestran Total de votos, Porcentaje y avance de votación por hora 
                por Partido Político, Candidatura Independiente, Coaliciones, Candidaturas 
                no registradas y votos nulos, así como los Distritos obtenidos en cada Circunscripción. 
                Puedes seleccionar desde uno a tres actores políticos para visualizar su detalle y cambiar 
                tu elección, agregando o eliminando opciones.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se muestran en Detalle por Entidad?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestran: Total de votos, Porcentaje y avance de votación por hora por Partido Político, 
                Candidatura independiente, Coaliciones, Candidaturas no registradas y votos nulos de las secciones 
                que conforman la Entidad elegida. Puedes seleccionar desde uno a tres actores políticos para 
                visualizar su detalle y cambiar tu elección, agregando o eliminando opciones.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se muestran en Detalle por Distrito?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestra la distribución de votos por candidatura a nivel Distrito, indicando el porcentaje, 
                total de votos y avance de votación por hora. Puedes agregar hasta tres opciones para ver el detalle,
                así como eliminar las opciones elegidas y agregar otras.
              </p>
            </div>            
          </div>

          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se muestran en Detalle por Sección?              
                </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestra el Total de votos y porcentaje por Partido Político, Candidatura Independiente, 
                Coalición, Candidatura no registrada y votos nulos de todas las secciones. Asimismo se puede 
                elegir una sección en específico, donde se indican el tipo de casilla, la imagen del Acta 
                digitalizada, los votos por actor político, votos nulos, el total y observaciones.                 
                También podrás observar el origen del Acta: Acta en proceso, Escáner, Dispositivo móvil y Urna 
                electrónica.
              </p>
            </div>            
          </div>

          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se muestran en Detalle por Casilla?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Puedes ver el resultado de votación en todas las casillas o por tipo de casilla. 
                Se mostrará el total de votos por Partido Político, Candidatura Independiente, 
                Coalición, Candidatura no registrada y votos nulos. Tienes la opción de visualizar 
                el Acta y su origen.
              </p>
            </div>            
          </div>


        </div>
        
       

      </div>

    </div>

    <div class="row contenedor " v-if="selectedOption === '3'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Conoce los resultados de tu Casilla -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cómo encuentro los resultados de mi casilla?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Ingresa tu Entidad, Sección y tu Clave de elector, que se encuentran en tu identificación oficial 
                y realiza la consulta. En escritorio encuentras esta búsqueda en la parte superior de la derecha 
                con el nombre de Conoce los resultados de tu casilla. En móvil, ingresa a Menú, selecciona la opción
                Mi Casilla y mete tus datos.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi sección?           
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                La sección que te corresponde se encuentra especificada en el frente de tu credencial de elector, 
                ejemplo: SECCIÓN 0610.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi clave de elector?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Tu clave de elector está especificada en el frente de tu credencial de elector, 
                ejemplo: CLAVE DE ELECTOR GMVLMR8007501M100.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Se guardará mi información en alguna base de datos?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Los datos que nos proporciones son exclusivos para visualizar el detalle de votación de tu casilla, 
                no se guardarán en ninguna base de datos.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Si no quiero ingresar mis datos cómo puedo buscar los resultados de mi casilla?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Selecciona la Circunscripción, Entidad, Distrito, Sección y Casilla desde las opciones de navegación.
              </p>
            </div>            
          </div>        


        </div>
        
       

      </div>

    </div>

    <div class="row contenedor2 " v-if="selectedOption === '4'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Votos en Actas Contabilizadas -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Qué muestra el Total de votos?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Muestra el número de votos que hasta el momento se han 
                contabilizado de acuerdo al reporte y detalle solicitados.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo ver el detalle de votación de los Partidos Políticos?           
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través de los reportes Votos por Distritos y Votos por Partido Político y Candidatura Independiente, 
                se muestra el resumen de votación para cada uno. Puedes detallar tu búsqueda al seleccionar Circunscripción, 
                Entidad, Sección y Casilla.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo consulto el detalle de votación por Candidatura Independiente?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través del reporte Votos por Distritos, selecciona la Circunscripción, Entidad y el Distrito que quieres visualizar. 
                Se muestra la sección Resumen de la votación indicando el Candidato, votos y porcentaje. Asimismo, en el reporte Votos 
                por Partido Político y Candidatura Independiente, selecciona la Circunscripción, Entidad y el Distrito que quieres visualizar. 
                Se mostrarán los votos de las Candidatura Independientes.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo consulto el detalle de votación por Coalición?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través del reporte Votos por Distritos, se visualiza el resumen 
                del total de votos y porcentaje obtenidos hasta el momento.
              </p>
            </div>            
          </div>

          <div class="row">
            <div>
              <h6>
                ¿Cómo consulto el Detalle de votos por Casilla?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través de los reportes Votos por Distritos y Votos por Partido Político y Candidatura Independiente, 
                selecciona Circunscripción, Entidad, Distrito, Sección y Casilla, se mostrará el resumen de la votación.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Cómo consulto el Detalle de votos por Casillas Especiales?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través de los reportes Votos por Distritos y Votos por Partido Político y Candidatura Independiente, 
                selecciona Circunscripción, Entidad, Distrito, Sección y el tipo de Casilla, se mostrará el resumen de la votación.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
               ¿Cómo consulto la Distribución de votos por Candidatura a nivel Distrito?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                A través del reporte Votos por Distritos, selecciona la Circunscripción, Entidad y el Distrito que quieres visualizar. 
                Se mostrará la sección resumen de la votación y en seguida la sección Distribución de votos por candidatura a nivel Distrito. 
                Aquí podrás observar el nombre del candidato y el total de votos que lleva cada uno.
              </p>
            </div>
          </div>         
          
          <div class="row">
            <div>
              <h6>
                ¿Dónde se indican los votos nulos?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se indican en todos los resultados de búsqueda de las opciones para visualizar el detalle: Circunscripción, Entidad, Distrito, Sección y Casilla.
              </p>
            </div>            
          </div> 
          
          <div class="row">
            <div>
              <h6>
                ¿Cómo consulto las Actas RP?             
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Ingresa al reporte Votos por Partido Político y Candidatura Independiente, luego selecciona 
                la Circunscripción y Entidad, se mostrará la sección Representación Proporcional, da clic en 
                Ver Actas RP y podrás ver el resumen de la votación.
              </p>
            </div>            
          </div> 
          
          <div class="row">
            <div>
              <h6>
                ¿Qué indica el estatus Actas en proceso?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica que el Acta aún no se ha digitalizado hasta el momento.
              </p>
            </div>            
          </div> 
          
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo saber la procedencia de la imagen del Acta y qué indica su origen?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde el resumen de votación por Casilla, se indicará por cuál medio se digitalizó el Acta: escáner, dispositivo móvil o urna electrónica.
              </p>
            </div>            
          </div> 
          <div class="row">
            <div>
              <h6>
                ¿Cuáles son las dos vistas en que puedo visualizar el resumen de votación?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde la versión móvil, puedes visualizar el reporte en tabla o gráfica, 
                dando clic en los íconos que están al inicio de la sección Resumen de la votación.
              </p>
            </div>            
          </div> 

        </div>
      </div>

    </div>

    <div class="row contenedor " v-if="selectedOption === '5'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Visualización tarjetas -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cuáles son los resultados que muestra el mapa electoral?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Te muestra el total de votos y porcentaje por Partido Político o Candidatura Independiente 
                que aventaja hasta el momento en cada división geográfica. Por ejemplo, en Circunscripción 
                te permite ver quién aventaja en cada Entidad y dentro de ésta, quién va adelante en cada Distrito.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo navegar en el mapa electoral?           
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde escritorio puedes dar clic sobre el Distrito que quieras revisar y en móvil puedes presionar sobre éste.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo ver el detalle por Circunscripción?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                En el mapa electoral se encuentran las Circunscripciones, Entidades y Distritos Federales. 
                Da clic sobre la Circunscripción que desees y se mostrará el Partido Político que aventaja, 
                los votos, el número de Circunscripción, la Entidad y el Distrito.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo ver el detalle por Entidad?             
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Al seleccionar la Entidad, se mostrará el mapa correspondiente a ésta y se mostrará el resumen de la votación.
              </p>
            </div>            
          </div>

          <div class="row">
            <div>
              <h6>
                ¿Cuáles son los resultados que muestra el gráfico de la Cámara?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestra el porcentaje de votos por Partido Político, Coalición y Candidatura Independiente. 
              </p>
            </div>            
          </div>

          

        </div>
      </div>

    </div>

    <div class="row contenedor2 " v-if="selectedOption === '6'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Visualización gráficos -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cuáles son los resultados que muestra el mapa electoral?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Te muestra el total de votos y porcentaje por Partido Político o Candidatura Independiente que 
                aventaja hasta el momento en cada división geográfica. Por ejemplo, en Circunscripción te permite 
                ver quién aventaja en cada Entidad y dentro de ésta, quién va adelante en cada Distrito.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo navegar en el mapa electoral?           
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde escritorio puedes dar clic sobre el Distrito que quieras revisar y en móvil puedes presionar sobre éste.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo ver el detalle por Circunscripción?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                En el mapa electoral se encuentran las Circunscripciones, Entidades y Distritos Federales. 
                Da clic sobre la Circunscripción que desees y se mostrará el Partido Político que aventaja, 
                los votos, el número de Circunscripción, la Entidad y el Distrito.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo ver el detalle por Entidad?             
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Al seleccionar la Entidad, se mostrará el mapa correspondiente a ésta y se mostrará el resumen de la votación.
              </p>
            </div>            
          </div>

          <div class="row">
            <div>
              <h6>
                ¿Cuáles son los resultados que muestra el gráfico de la Cámara?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestra el porcentaje de votos por Partido Político, Coalición y Candidatura Independiente. 
              </p>
            </div>            
          </div>

          

        </div>
      </div>

    </div>

    <div class="row contenedor " v-if="selectedOption === '7'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Ver detalle -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cómo encuentro los resultados de mi casilla?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Ingresa tu Entidad, Sección y tu Clave de elector, que se encuentran en tu identificación 
                oficial y realiza la consulta. En escritorio encuentras esta búsqueda en la parte superior 
                de la derecha con el nombre de Conoce los resultados de tu casilla. En móvil, ingresa a Menú, 
                selecciona la opción Mi Casilla y mete tus datos.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi sección?         
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                La sección que te corresponde se encuentra especificada en el frente de tu credencial de elector, ejemplo: SECCIÓN 0610.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi clave de elector?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Tu clave de elector está especificada en el frente de tu credencial de elector, ejemplo: CLAVE DE ELECTOR GMVLMR8007501M100.
              </p>
            </div>            
          </div>        

          

        </div>
      </div>

    </div>

    <div class="row contenedor2 " v-if="selectedOption === '8'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Resumen de la votación -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cómo encuentro los resultados de mi casilla?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Ingresa tu Entidad, Sección y tu Clave de elector, que se encuentran en tu identificación 
                oficial y realiza la consulta. En escritorio encuentras esta búsqueda en la parte superior 
                de la derecha con el nombre de Conoce los resultados de tu casilla. En móvil, ingresa a Menú, 
                selecciona la opción Mi Casilla y mete tus datos.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi sección?         
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                La sección que te corresponde se encuentra especificada en el frente de tu credencial de elector, ejemplo: SECCIÓN 0610.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cuál es mi clave de elector?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Tu clave de elector está especificada en el frente de tu credencial de elector, ejemplo: CLAVE DE ELECTOR GMVLMR8007501M100.
              </p>
            </div>            
          </div>       
          
          <div class="row">
            <div>
              <h6>
                ¿Se guardará mi información en alguna base de datos?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Los datos que nos proporciones son exclusivos para visualizar el detalle de votación de tu casilla, no se guardarán en ninguna base de datos.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Si no quiero ingresar mis datos cómo puedo buscar los resultados de mi casilla?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Selecciona la Circunscripción, Entidad, Distrito, Sección y Casilla desde las opciones de navegación.
              </p>
            </div>            
          </div> 

          

        </div>
      </div>

    </div>

    <div class="row contenedor " v-if="selectedOption === '9'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Estadística -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística Nacional?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                En esta sección encontrarás la gráfica del Porcentaje de Actas Esperadas, 
                Capturadas y Contabilizadas, al momento, así como el total de las mismas. 
                El número de Actas contabilizadas por Casillas Urbanas y No Urbanas. 
                El porcentaje de la Participación ciudadana conforme a las Actas Contabilizadas, 
                sin considerar la votación en Casillas Especiales. El gráfico de los votos en Casillas
                Básicas, Contiguas y Extraordinarias; votos en Casillas Especiales y el Total. 
                El gráfico de la Lista Nominal de Actas Contabilizadas en comparación con la Lista Nominal oficial.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Circunscripción?         
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos de la Circunscripción seleccionada: gráfico del porcentaje de Actas Esperadas, 
                Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas sin considerar 
                la votación en las Casillas Especiales. El gráfico de los votos en Casillas Básicas, Contiguas y Extraordinarias; 
                votos en Casillas Especiales y el Total. El gráfico de la Lista Nominal de Actas Contabilizadas en comparación con 
                la Lista Nominal oficial.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Entidad?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos de la Entidad seleccionada: gráfico del porcentaje de Actas Esperadas, 
                Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas sin 
                considerar la votación en las Casillas Especiales y el gráfico del Total de votos de la Lista Nominal.
              </p>
            </div>            
          </div>       
          
          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Distrito?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos del Distrito seleccionado: gráfico del porcentaje de Actas Esperadas, 
                Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas 
                sin considerar la votación en las Casillas Especiales y el gráfico del Total de votos de la Lista Nominal.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Sección?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos de la Sección seleccionada: gráfico de la Participación 
                ciudadana conforme a las Actas Contabilizadas, indicando el porcentaje de la votación en las 
                casillas de esa sección en contraste con el Total de la Lista Nominal.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Sección?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos de la Sección seleccionada: gráfico de la Participación 
                ciudadana conforme a las Actas Contabilizadas, indicando el porcentaje de la votación en las 
                casillas de esa sección en contraste con el Total de la Lista Nominal.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué datos se muestran en la sección Estadística por Casilla?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se mostrarán los datos específicos de la Casilla seleccionada: gráfico de la Participación 
                ciudadana conforme a las Actas Contabilizadas, indicando el porcentaje de la votación en la 
                casilla en contraste con el Total de la Lista Nominal.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué indica el reporte de las Actas Esperadas, Capturadas y Contabilizadas?             
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica el número de Actas de todas las casillas aprobadas por el Consejo Distrital respectivo, 
                por cada por la elección. Total de Actas registradas en el sistema, que están dentro del catálogo 
                de Actas Esperadas. Actas de las casillas aprobadas, capturadas y sumadas.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué resultados se reportan en Actas Contabilizadas por Casillas Urbanas y No Urbanas?             
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se refiere a las Actas que se han capturado en las casillas ubicadas en la ciudad y en las zonas rurales.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué indica el porcentaje de Participación Ciudadana conforme a las Actas Contabilizadas?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica el porcentaje de personas que han participado en la votación 
                en relación a las Actas que se han contabilizado en una casilla.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué indica la gráfica de Votos en Casillas Básicas, Contiguas y Extraordinarias?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Indica los votos que se han capturado y contabilizado hasta el momento del corte en este tipo de casilla.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Cuál es el resultado que se reporta en el gráfico de la Lista nominal?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                El resultado es el número de votantes que han ejercido su voto, en contraste 
                con la Lista Nominal oficial esperada, es decir, todos las personas que están 
                registradas y tienen derecho a votar.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Cómo descargo la base de datos?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Al ingresar podrás consultar una guía para realizar la descarga e interpretación de la Base de datos.
              </p>
            </div>            
          </div> 
          <div class="row">
            <div>
              <h6>
                ¿En Participación ciudadana conforme a las Actas Contabilizadas, cuándo sí se muestra la gráfica de votos en las casillas?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se muestra esta gráfica cuando te encuentras en los niveles: Nacional y Circunscripción. 
                En Entidad, Sección y Casilla no se visualiza.
              </p>
            </div>            
          </div> 
          <div class="row">
            <div>
              <h6>
                ¿Qué implican las Actas con inconsistencias?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Implican una revisión detallada para determinar cuál es la inconsistencia que presentan, 
                con base en esto determinar si son contabilizadas, no contabilizadas o fuera de catálogo.
              </p>
            </div>            
          </div> 
          

        </div>
      </div>

    </div>
    <div class="row contenedor2 " v-if="selectedOption === '10'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Observaciones en Actas -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Cuál es la información que se puede compartir y en dónde?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Se puede compartir la página del PREP en redes sociales.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo cambiar a versión escritorio o versión móvil?         
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde la computadora puedes seleccionar la opción minimizar, ubicada en la parte superior derecha 
                de tu pantalla y podrás visualizar la versión móvil. Para regresar a versión escritorio, selecciona 
                la opción maximizar. Desde tu móvil selecciona Versión escritorio, ubicada en la parte final de la pantalla.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo poner modo nocturno?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Solo debes dar clic en Ajustes y seleccionar el tema Oscuro.
              </p>
            </div>            
          </div> 
        </div>
      </div>

    </div>
    <div class="row contenedor " v-if="selectedOption === '11'">
      <div class="col-12 col-lg-10 ">        
        <h5 class="font-medium pb-4 text-left pt-4">
          - Elementos de apoyo -
        </h5>
        <div class="container text-justify ">
          <div class="row">
            <div>
              <h6>
                ¿Es necesario actualizar en cada momento?
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                No es necesario actualizar todo el tiempo, ya que la información en el PREP se actualiza 
                cada 15 minutos. Sin embargo, puedes dar clic en el botón Actualizar para refrescar la página.
              </p>
            </div>
            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿Cómo puedo regresar a la página principal?         
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Puedes regresar a la página principal dándole clic en el logotipo del PREP, 
                que se encuentra en la parte superior de la derecha. Si le das clic al logotipo 
                del INE, te llevará a la página del INE.
              </p>
            </div>            
          </div>
          <div class="row">
            <div>
              <h6>
                ¿El chat está disponible las 24 horas?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                El servicio de chat estará activo únicamente por 24 horas, que empieza a las 8 pm del domingo, 
                hasta las 8 pm del lunes, es el mismo tiempo que el PREP está contando las actas. En estas horas 
                podrás atender las dudas correspondientes a la publicación de los resultados preliminares.
              </p>
            </div>            
          </div>       
          
          <div class="row">
            <div>
              <h6>
                ¿En las versiones móvil y escritorio puedo encontrar la misma información?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Desde luego, tanto en la versión móvil como en la de escritorio encontrarás la misma información de los resultados publicados en el PREP.
              </p>
            </div>            
          </div> 

          <div class="row">
            <div>
              <h6>
                ¿Qué información encontraré en la sección Ayuda?              
              </h6>
            </div>
            <div class="descripcion">              
              <p>
                Al dar clic en Ayuda ingresarás al sitio Centro de ayuda, donde encontrarás 
                la explicación detallada de los elementos comprendidos en el proceso de votación: 
                Qué es el PREP, Elecciones Federales, Voto y Tipos de Actas.
              </p>
            </div>            
          </div> 
        </div>
      </div>

    </div>



  </layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout.vue'

export default {
  name: 'PreguntasFrecuentesMovil',
  components: {
    Layout
  },
  data() {
    return {
      selectedOption: '1',
      options: [
        { value: '1', label: 'Avance Nacional ' },
        { value: '2', label: 'Consulta de la votación' },
        { value: '3', label: 'Conoce los resultados de tu Casilla' },
        { value: '4', label: 'Votos en Actas Contabilizadas' },
        { value: '5', label: 'Visualización de tarjetas' },
        { value: '6', label: 'Visualización gráficos' },
        { value: '7', label: 'Ver detalle' },
        { value: '8', label: 'Resumen de la votación' },
        { value: '9', label: 'Estadística' },
        { value: '10', label: 'Observaciones en Actas' },
        { value: '11', label: 'Elementos de apoyo' },
      ]
    };
  },
  
}
</script>

<style lang="scss" scoped>
// .morado {
//   background-color:#582D73;
//   width: 100%;
//   height: 200px;
//   color: #f2f2f2;
//   padding-top: 00px;
// }
body {
  margin: 0; /* Elimina el margen predeterminado del cuerpo */
  padding: 0; /* Elimina el relleno predeterminado del cuerpo */

}

.contenedor{
  background-color: #F4F4F4;
  border-radius: 10px;
  margin-top: 180px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    // align-items: center;
    height: 80px;
    margin-top: 25px;
  }
}

.contenedor2{
  background-color: #B283B9;
  border-radius: 10px;
  margin-top: 180px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  
  .descargar2{
    .boton-rosa{
      background-color:#C791A8 ;
      border-radius: 10px;
      width: 260px;
      height: 50px;
      text-align: center;
      padding-top: 13px;
      font-weight: bold;
    }
  }
 .descripcion{
  p{
    font-size: 14px;
  }
 }
  img{
    // align-items: center;
    height: 80px;
    margin-top: 25px;
  }
}
// .contenedor2{
//   background-color: #F4F4F4;
//   border-radius: 10px;
//   // margin-top: 150px;
//   margin-left: 20px;
//   margin-right: 20px;
//   margin-bottom: 20px;
//   position: relative;
//   z-index: 2;
  
//   .descargar2{
//     .boton-rosa{
//       background-color:#C791A8 ;
//       border-radius: 10px;
//       width: 260px;
//       height: 50px;
//       text-align: center;
//       padding-top: 13px;
//       font-weight: bold;
//     }
//   }
//  .descripcion{
//   p{
//     font-size: 14px;
//   }
//  }
//   img{
//     // align-items: center;
//     height: 80px;
//     margin-top: 25px;
//   }
// }
.claro {
  color: #582D73;
}
.morado {
  background-color:#582D73;
  color: #f2f2f2;
  width: 100%;
  height: 220px;
  position: absolute;
  z-index: 1;
    .opciones{
      display: flex;
      justify-content: center;

      select{
        width: 350px;
        height: 40px;
        border-radius: 10px;
        
      }
  }
}
.gris{
  background-color: #F4F4F4;
}
.claro {
  color: #582D73;
}
</style>
