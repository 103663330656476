<template>
<div class="votos-por-distritos">
  <div class="tarjetas">
    <ul>
      <li v-for="(municipio, index) in votos.datos_candidatos" :key="index">
        <div>
          <div class="partidos-distrito">
            <div class="partidos">
              <ul>
                <li>
                  <div>
                    <img :src="municipio.candidatura_logo" alt="">
                  </div>
                </li>
              </ul>
            </div>
            <div class="distritos">
              <h3 v-if="eleccion === 'A'">Municipios</h3>
              <h3 v-else>Distritos</h3>
              <span v-if="eleccion === 'D'">
                {{municipio.candidatura_distritos_obtenidos_numero}}
              </span>
              <span v-else>
                {{municipio.candidatura_municipios_obtenidos_numero}}
              </span>
            </div>
          </div>
          <div class="votos">
              <div>
                <h3>Votos</h3>
                <div>
                    <span> <strong>{{municipio.candidatura_total_votos_numero}}</strong> </span>
                    <span>{{municipio.candidatura_total_votos_porcentaje}}%</span>
                </div>
              </div>
          </div>
          <b-form-checkbox
            class="boton-seleccionar seleccionado"
            button
            v-model="municipio.seleccionado"
            v-if="municipio.seleccionado"
          >
            Quitar selección
          </b-form-checkbox>
          <b-form-checkbox
            class="boton-seleccionar"
            button
            v-model="municipio.seleccionado"
            v-else
            :disabled="tresSeleccionados"
          >
            Seleccionar
          </b-form-checkbox>
        </div>
      </li>
    </ul>
  </div>

  <div class="resumen mb-3">
    <div class="info">
      <p>
        <font-awesome-icon icon="info-circle"/> Da clic en
        <strong>Seleccionar</strong> para visualizar el detalle de la
        votación. Puedes seleccionar hasta tres opciones.
      </p>
      <button
        class="boton-azul"
        v-b-modal.DetalleEleccionDistritosModal
        :disabled="!alMenosUnSeleccionado"
      >
        Ver detalle
      </button>
    </div>
  </div>

  <div class="text-left mb-3" v-if="eleccion === 'D'">
    <p class="mb-1">
      <b>
        No se considera la cantidad de votos asentada
        en las actas PREP de Casilla Especial de Representación
        Proporcional.
      </b>
    </p>
  </div>

  <detalle-eleccion-distritos
    :resumen="gubernaturaResumenNivelEntidad"
    :votos="votosXDistritoTarjeta"
    :esXDistritos="true"
  />
</div>
</template>

<script>
import DetalleEleccionDistritos from '@/components/DetalleEleccionDistritos.vue';

export default {
  name: 'ResumenTarjetasAyuntamientos',
  props: {
    detalle: Object,
  },
  components: {
    DetalleEleccionDistritos,
  },
  data() {
    return {
      random: 0,
      votos: this.detalle,
    };
  },
  computed: {
    tresSeleccionados() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados === 3;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    gubernaturaResumenNivelEntidad() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }
      return this.$store.state.gResumenNivelEntidad;
    },
    votosXDistritoTarjeta() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMapa;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMapa;
      }

      return {};
    },
    alMenosUnSeleccionado() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados >= 1;
    },
  },
};
</script>
