<template>
  <div>
    <BaseDatosEscritorio v-if="anchoPantalla >= 992" />
    <BaseDatosMovil v-else />
  </div>
</template>

<script>
import BaseDatosEscritorio from '@/components/Escritorio/vistas/BaseDatos.vue'
import BaseDatosMovil from '@/components/Movil/vistas/BaseDatos.vue'

export default {
  name: 'BaseDatos',
  components: {
    BaseDatosEscritorio,
    BaseDatosMovil,
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024 - Base de datos'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
}
</script>
