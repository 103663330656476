<template>
  <section class="acceso-representacion-proporcional fondo d-flex justify-content-center mb-4"> 
    
    <div class="">
      <div class="icono mb-4 ml-3 mt-4">
       <img src="/img/votoA.png" >
      </div>

      <p class="text-center font-weight-bold">
        <big>
          Voto Anticipado
        </big>
      </p>

    

      <router-link to="/voto-anticipado" class="btn btn-primary d-block mx-auto  mb-3">
        Ver Resultados
      </router-link>

    </div>
  
  </section>
</template>

<script>
  export default {
    name: 'AccesoVotoAnticipadoPorDistrito'
  }
</script>

<style lang="scss" scoped>
.acceso-representacion-proporcional {
  
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 45px;
  padding: 30px;
  border-radius: 15px;
  //width: 2000px;
  background-color: #ffff;
  img{
    padding-left: 15px;
  }

  p{
    color: #582D73;
    font-size: 20px;
  }
  
  .btn {
    background-color:#C791A8;
    color: #000000;
    font-weight: bold;
    border-color: transparent;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: 992px) {
    max-width: 100%;

    display: block;
    margin: 0 auto;

    .btn {
      max-width: 200px;
      font-size:medium ;
      border-radius: 10px;
    }
  }
}

@media (max-width: 768px) {
  .acceso-representacion-proporcional{
    // background-color:#ffffff ;
    width: 110%;

    img{
      padding-left: 45px;
      height: 60px;
      align-items: center;
    }
    p{
      font-size: 15px;
      text-align: justify;
      big{
        font-size: 20px;
        font-weight: bolder;
      }    
    } 
    .btn {
      width: 200px;
      border-radius: 10px;

  }
  }

}
</style>