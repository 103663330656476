<template>
  <div class="row">
    <div
      class="col-12 mb-4 grilla"
      v-for="(candidato, index) in noSeleccionados"
      :key="index"
    >
      <div class="avatar">
       <img
          :src="candidato.candidato_foto_url"
          alt=""
          style="max-height: 40px;"
        />
      </div>
      <div class="detalle">
        <div>
          <span>{{ candidato.candidato_nombre }} {{ candidato.candidato_apellidos }}</span> <br />
          <div class="float-left mb-2">
            <img
              :src="candidato.candidatura_logo"
              style="max-height: 40px;"
            />
          </div>
        </div>
      </div>
      <div class="boton">
        <button @click="candidato.seleccionado = true">
          <font-awesome-icon icon="plus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeleccionDetalle',
  props: {
    noSeleccionados: Array,
  },
};
</script>

<style lang="scss" scoped>
.grilla {
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  align-items: center;
  gap: 45px;
  border-bottom: 2px solid #a2a2a2;

  .boton {
    button {
      background-color: #582D73;
      border: none;
      padding: 5px 10px;
      border-radius: 50%;
      color: #FFF;
    }
  }

  &:last-child {
    border: none;
  }
}
</style>
