<template>
  <layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <h4>
          {{ titulo }} -
          <b class="primario">Entidad </b> -
          <span class="primario">{{ subtitulo }}</span>
        </h4>

        <p>
          <b>
            Baja California Sur
          </b>
        </p>

        <p>
          El total de votos calculado y porentaje que se muestran, se refieren a los votos
          asentados en las Actas PREP hasta el momento.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo cuatro digitos.
          No obstante, al considera todos los decimales, suman 100%.
        </p>

          <div >
            <MapaDistritos :detalle="detalle" :key="random" :esDistritos="eleccion === 'D'" style="width: 100%; margin: 0 auto;" />
          </div>




        <div class="votosEnActasContabilizadas">
          <p class="titulo mb-4">
            Votos en Actas Contabilizadas
          </p>


        </div>

        <p class="mb-5 mt-3">
          Se resalta el Partido Político, Coalición, Candidatura Independiente que lleva
          ventaja hasta el momento.
        </p>

        <div class="row" v-if="eleccion === 'G'">
          <VotoPorCandidatura
            v-for="(candidato, index) in votos.datos_candidatos"
            :key="index"
            :candidato="candidato"
            :ganador="votosXCandidato.candidato_id_con_mayor_numero_votos"
            :tresSeleccionados="tresSeleccionados"
          />
        </div>

        <div class="row resumenCandidaturaDistritos" v-else>
          <div
            class="col-12 col-mb-6 tarjeta"
            v-for="(partido, index) in votos.datos_candidatos"
            :key="index"
          >
            <div
              class="contenedor mb-3"
              :style="
                votosXDistritos.candidatura_id_con_mayor_numero_votos === partido.candidatura_id
                  ? calcularColorBorde(partido)
                  : ''
              ">
              <div class="grilla-superior">
                <div class="logo">
                  <img :src="partido.candidatura_logo" alt="">
                </div>

              </div>
              <div class="grilla-inferior">
                <div class="votos">
                  <p class="mb-0">
                    <b>Total de votos</b> <br />
                    {{ partido.candidatura_total_votos_numero }}
                  </p>
                </div>
                <div class="porcentaje">

                </div>
                <div class="distritos text-right">
                  <div class="mb-0">
                    <div v-if="eleccion === 'D'">
                      <b>Distritos</b><br />{{ partido.candidatura_distritos_obtenidos_numero }}
                    </div>
                    <div>
                      <b>Porcentaje</b><br />{{ partido.candidatura_total_votos_porcentaje }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>

        </div>

        <!--CANDIDATURAS NO REGISTRADOS-->
        <div class="row resumenCandidaturaDistritos">
          <div
            class="col-12 col-mb-6 tarjeta"
          >
            <div class="contenedor mb-3">
              <div class="grilla-superior">
                <div class="logo">
                  <!-- <img :src="partido.candidatura_logo" alt=""> -->
                  Candidaturas no registradas
                </div>
              </div>
              <div class="grilla-inferior">
                <div class="votos">
                  <p class="mb-0">
                    <b>Total de votos</b> <br />
                    {{resumen.total_votos_candidatura_noregistrada_numero}}
                  </p>
                </div>
                <div class="porcentaje">

                </div>
                <div class="distritos text-right">
                  <div class="mb-0">
                    <div v-if="eleccion === 'D'">
                    </div>
                    <div>
                      <b>Porcentaje</b><br />{{ resumen.total_votos_candidatura_noregistrada_porcentaje}}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--VOTOS NULOS-->
        <div class="row resumenCandidaturaDistritos">
          <div
            class="col-12 col-mb-6 tarjeta"
          >
            <div class="contenedor mb-3">
              <div class="grilla-superior">
                <div class="logo">
                  <!-- <img :src="partido.candidatura_logo" alt=""> -->
                  Votos nulos
                </div>
              </div>
              <div class="grilla-inferior">
                <div class="votos">
                  <p class="mb-0">
                    <b>Total de votos</b> <br />
                    {{resumen.total_votos_nulos_numero}}
                  </p>
                </div>
                <div class="porcentaje">

                </div>
                <div class="distritos text-right">
                  <div class="mb-0">
                    <div v-if="eleccion === 'D'">
                    </div>
                    <div>
                      <b>Porcentaje</b><br />{{ resumen.total_votos_nulos_porcentaje}}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="resumenDeLaVotacion mb-5">
          <p v-if="eleccion === 'D'">
            Por presentación, los decimales de los porcentajes muestran sólo cuatro digitos. No obstante, al considera todos los decimales, suman 100%.
          </p>
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion
            v-if="eleccion === 'G'" texto="En la Entidad"
            :numero="resumen.total_votos_por_entidad_numero"
            :porcentaje="resumen.total_votos_por_entidad_porcentaje"
          />
          <ResumenVotacion
            v-if="eleccion === 'G'"
            texto="En el Extranjero"
            :numero="resumen.total_votos_extranjero_numero"
            :porcentaje="resumen.total_votos_extranjero_porcentaje"
          />

          <div class="borde">
              <div class="d-flex ">
              <div class="icono pt-4 mr-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <ResumenVotacion
              texto="Votos acumulados"
              :numero="resumen.total_votos_por_entidad_numero"
              :porcentaje="resumen.total_votos_por_entidad_porcentaje"
            />
            <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoAcumulado"
                  />
            </span>

            <ModalBaseMovil :textoModal="votoAcumulado"  ref="modalRefVotoAcumulado" />


            </div>
            <div class="d-flex ">
              <div class="icono-suma pt-4 mt-4 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
            <ResumenVotacion
              texto="Candidaturas no registradas"
              :numero="resumen.total_votos_candidatura_noregistrada_numero"
              :porcentaje="resumen.total_votos_candidatura_noregistrada_porcentaje"
            />
            </div>

            <div class="d-flex">
              <div class="icono-suma pt-4 mt-3 mr-2 ml-2">
                      <font-awesome-icon icon="plus" class="fa-2x" />
              </div>


                <ResumenVotacion
                texto="Votos Nulos"
                :numero="resumen.total_votos_nulos_numero"
                :porcentaje="resumen.total_votos_nulos_porcentaje"

                />
                <span id="tooltip" class="mt-3">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg"
                    @click="mostrarModalVotoNulo"
                  />
                </span>
                  <ModalBaseMovil :textoModal="votoNulo"  ref="modalRefVotoNulo" />


            </div>
          </div>
          <div class="total-votos mt-5">
           <div class="d-flex justify-content-center">
            <h5 class="text-center mr-2 mt-2">Total de votos</h5>

                <span id="tooltip" class="info-votos">
                  <font-awesome-icon
                    icon="info-circle"
                    class="fa fa-info fa-lg "
                    @click="mostrarModalTotalVotos"
                    />

                </span>
                <ModalBaseMovil :textoModal="totalVotos" v-if="esVotototal" ref="modalRefVotosTotales" />



           </div>
            <div class="d-flex">
              <div class="icono-suma ml-4 mt-2 pt-1">
                      <font-awesome-icon icon="equals" class="fa-2x" />
              </div>

              <div class=" ml-5  text-center ">
                <h2>{{ resumen.total_votos_general_numero }}</h2>
              </div>

            </div>
            <div class="text-center ">
              <h4>{{ resumen.total_votos_general_porcentaje }}%
              </h4>
            </div>
          </div>
         
        </div>

        <div class="votoEnElExtranjero px-4 py-2 text-center mb-5" v-if="eleccion === 'G'">
          <font-awesome-icon icon="globe-americas" class="fa-3x mb-3" />
          <h4 class="font-weight-bold mb-3">
            Voto en el Extranjero
          </h4>

          <router-link to="/G/VE" class="btn btn-primary mx-auto d-block btn-actas px-4 w-50">
            Ver actas
          </router-link>
        </div>

        <div class="votosEnActasContabilizadas ">
          <p class="titulo">
            Estadística Entidad
          </p>
        </div>

        <estadistica-entidad />
        <lista-nominal />
        <porcentaje-participacion />
      </div>

      <detalle-eleccion-candidaturas
        v-if="eleccion === 'G'" :resumen="resumen" :votos="votos" :esCandidato="true"
      />
      <detalle-eleccion-distritos v-if="eleccion === 'D' || eleccion === 'A'"
      :resumen="resumen" :votos="votos" />
    </div>
  </layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import VotoPorCandidatura from '@/components/Movil/componentes/VotoPorCandidatura.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';
import DetalleEleccionDistritos from '@/components/DetalleEleccionDistritos.vue';
import MapaDistritos from '@/components/Movil/componentes/MapaDistritos/Index.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import ListaNominal from '@/components/ListaNominal.vue';
import PorcentajeParticipacion from '@/components/PorcentajeParticipacion.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';

import AccesoVotoAnticipadoPorDistrito from '@/components/Movil/componentes/AccesoVotoAnticipadoPorDistrito.vue';


export default {
  name: 'VotosPorCandidaturasMovil',
  components: {
    Layout,
    VotoPorCandidatura,
    ResumenVotacion,
    AccesoVotoAnticipadoPorDistrito,
    ModalBaseMovil,
    DetalleEleccionCandidaturas,
    MapaDistritos,
    DetalleEleccionDistritos,
    EstadisticaEntidad,
    ListaNominal,
    PorcentajeParticipacion,

  },
  data() {
    return {
      random: 0,
      esMapa: true,
      votos: [],
      show: false,
      esVotototal: true,
      votoAcumulado:
      "Es la suma de votos obtenidos por Partido Político, Coalición y Candidatura Independiente, sin incluir los votos de candidaturas no registradas ni nulos.",
      totalVotos:
      "Se refiere a la suma total de votos que realiza el sistema informático con base en los datos que muestran en cada Acta; podrás consultar la cifra del total de votos mostrados en cada Acta en la base de datos de PREP (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).",
      votoNulo:
      "El total y el porcentaje de votos para candidaturas no registradas y nulos, considera la votación en Casillas y Anticipados." ,
    };

  },
  watch: {
    '$route.params.eleccion': function () {
      this.$forceUpdate();
      this.random = Math.random();
    },
  },
  computed: {
    tresSeleccionados() {
      let seleccionados = 0;
      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura'; }
      if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento'; }
      return 'Diputaciones';
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }

      return {};
    },
    detalle() {
      return {
        datos: this.$route.params.eleccion === 'D'
          ? this.$store.state.dVotosMapa : this.$store.state.aVotosMapa,
        eleccion: this.$route.params.eleccion,
        esMapa: this.esMapa,
      };
    },
    esOscuro() {
      return this.$store.state.esOscuro;
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'f1b1d7';
      }
      return 'd2127e';
    },
    colorNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'e97ebd';
      }
      return '79144c';
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    subtitulo() {
      if (this.$router.currentRoute.name === 'VotosPorCandidaturas' && (this.$route.params.eleccion === 'G' || this.$route.params.eleccion === 'A')) {
        return 'Votos por Municipios';
      }
      return 'Votos por Distritos ';
    },
    votosXCandidato() {
      return this.$store.state.votosXCandidatoTarjeta;
    },
    votosXDistritos() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMapa;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMapa;
      }

      return {};
    },
    votosXPartidoGraficas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXPartidoGraficas;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXPartidoGraficas;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXPartidoGraficas;
      }

      return {};
    },
    alMenosUnSeleccionado() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados >= 1;
    },
  },
  methods: {
    mostrarModalVotoNulo() {
      this.$refs.modalRefVotoNulo.show();

    },
    mostrarModalVotoAcumulado() {
      this.$refs.modalRefVotoAcumulado.show();

    },
    mostrarModalTotalVotos() {
      this.$refs.modalRefVotosTotales.show();
    },
    calcularColorBorde(partido) {
      if (!this.esOscuro) {
        return `
          border-top: 2px solid #D3097F !important;
          border-right: 2px solid #D3097F !important;
          border-bottom: 2px solid #D3097F !important;
          border-left-color: ${partido.candidatura_color} !important;
          color: #D3097F !important;
        `;
      } if (this.esOscuro) {
        return `
          border-top: 2px solid #e97ebd !important;
          border-right: 2px solid #e97ebd !important;
          border-bottom: 2px solid #e97ebd !important;
          border-left-color: ${partido.candidatura_color} !important;
          color: #e97ebd !important;
        `;
      }

      return `
        border-left-color: ${partido.color};
      `;
    },
    actualizarDetalleEleccion() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    setearVotos() {
      if (this.cargaTerminada) {
        if (this.eleccion === 'G') {
          this.votos = this.$store.state?.votosXCandidatoTarjeta;
        } else if (this.eleccion === 'D') {
          this.votos = this.$store.state?.dVotosMapa;
        } else {
          this.votos = this.$store.state?.aVotosMapa;
        }
      }
    },
    desplegarRegion(e, r) {
      if (this.seleccionRegion) {
        this.mostrarRegion();
      }
      this.$nextTick(() => {
        this.region.estado = true;
        this.region.map = r;
        this.region.event = e;
        this.region.tipo = this.detalle.eleccion;
        this.region.esDiputaciones = this.esDiputaciones;
        this.seleccionRegion = true;
        this.mapa.forEach((m) => {
          if (m.id !== r.id) {
            m.classList.add('opacidad_mapa');
          } else {
            m.classList.remove('opacidad_mapa');
          }
        });
      });
    },
  },
  created() {
    this.setearVotos();
  },
  updated() {
    this.setearVotos();
  },
};
</script>

<style lang="scss" scoped>
button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.fondo{
    .btn-va{
      background-color: #C791a8;
      border: none;
      border-radius: 10px;
    }

}

.total-votos{

  button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  }
  h2{
    font-size: 48px;
    font-weight: 900;
    color:#582D73;
  }
  h4{
    color:#582D73;

  }
  h5{
    font-weight: 700;
  }

}
.borde{
  border: 1px solid #ddd;
  border-spacing:5px;
}

.icono {
  background-color: #d3097f00;
  svg {
    color: #d3097f00;
  }
}
.resumenCandidaturaDistritos {
  .tarjeta {
    margin-bottom: 15px;
    .contenedor {
      border-left: 6px solid;
      background-color: #efefef;
      padding: 15px;
      .grilla-superior {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 50px;
        }
      }
      .grilla-inferior {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        gap: 15px;
      }
    }
    .contenedor2 {
      //border-left: 6px solid;
      background-color: rgb(134, 68, 109, 0.15);
      padding: 15px;

      .grilla-superior {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 50px;
        }
      }
      .grilla-inferior {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        gap: 15px;
      }
    }
    /* .ganador {
      border-top: 2px solid #D3097F;
      border-right: 2px solid #D3097F;
      border-bottom: 2px solid #D3097F;
      color: #D3097F;
    } */
  }
}

.votoEnElExtranjero {
  background-image: url('../../../../assets/Fondo_Mapa.png');
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
