<template>
  <div class="row">
    <div class="col-4 mb-3">
      <h4>
        Entidad <br />
        <small>
          Baja California Sur
        </small>
      </h4>
    </div>
    <div class="col-8 mb-3">
      <Mapa
        v-if="esXDistritos"
        :esDiputaciones="esXDistritos"
        :key="random"
      />
      <Mapa
        v-else
        :esDiputaciones="esXDistritos"
        :key="random"
      />
    </div>

    <div
      class="col-12 border mb-3"
      v-for="(seleccionado, index) in seleccionados"
      :key="index"
    >
      <div class="row align-items-center rowSeleccionado">
        <div class="col-6">
          <img
            :src="seleccionado.candidatura_logo"
            :alt="seleccionado.candidatura_nombre"
            class="img-fluid float-left my-3"
            style="max-width: 60px;"
          >
        </div>
        <div class="col-6">
          <button @click="seleccionado.seleccionado = false" class="float-right boton-eliminar">
            <font-awesome-icon icon="trash-alt" />
          </button>
        </div>
        <div class="col-5 elemento-barra">
          <p class="mb-0 font-weight-bold small">
            Total de votos <br />
            {{ seleccionado.candidatura_total_votos_numero }}
          </p>
        </div>
        <div class="col-4 elemento-barra">
          <p class="mb-0 font-weight-bold small">
            Porcentaje <br />
            {{ seleccionado.candidatura_total_votos_porcentaje }}%
          </p>
        </div>
        <div class="col-3 elemento-barra">
          <p class="mb-0 font-weight-bold small" v-if="eleccion === 'D'">
            Distritos <br />
            {{ seleccionado.candidatura_distritos_obtenidos_numero }}
          </p>
          <p class="mb-0 font-weight-bold" v-if="eleccion === 'A'">
            Municipios <br />
            {{ seleccionado.candidatura_municipios_obtenidos_numero }}
          </p>
        </div>
        <div class="col-12 px-0 progress-bar">
          <b-progress
            class="progreso-detalle-movil"
            max="100"
            :style="`background-color: #e2e2e2;`"
          >
            <b-progress-bar
              :style="`background-color: ${seleccionado.candidatura_color}; !important;`"
              :value="seleccionado.candidatura_total_votos_porcentaje"
            />
          </b-progress>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3">
      <b-btn
        variant="primary"
        class="mx-auto d-block"
        @click="seen = !seen" v-if="seleccionados.length < 3"
      >
        Agregar
      </b-btn>
    </div>

    <div class="opciones col-12" v-if="seen && seleccionados.length < 3">
      <p class="leyenda">
        Puedes agregar hasta tres opciones con el botón
        <font-awesome-icon icon="plus" class="fa-1x" />
      </p>

      <div class="grilla-seleccionar">
        <div
          class="elemento"
          v-for="(candidato, index) in noSeleccionados"
          :key="index"
        >
          <div class="detalle">
            <div>
              <div class="mb-2">
                <img
                  :src="candidato.candidatura_logo"
                  class="mx-auto d-block"
                  style="width:40px; height:40px"
                />
              </div>
            </div>
          </div>
          <div class="boton">
            <button class="primary" @click="candidato.seleccionado = true">
              <font-awesome-icon icon="plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mapa from '@/components/Mapa.vue';

export default {
  name: 'DetalleCardDistritos',
  props: {
    seleccionados: Array,
    noSeleccionados: Array,
    esXDistritos: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Mapa,
  },
  data() {
    return {
      random: Math.random(),
      seen: false,
    };
  },
  computed: {
    eleccion() {
      return this.$route.params.eleccion;
    },
  },
};
</script>

<style lang="scss" scoped>
.rowSeleccionado {
  .boton-eliminar {
    background: transparent;
    border: none;
    font-size: 1.4rem;
  }

  .elemento-barra {
    z-index: 1;
  }

  .progress-bar {
    position: absolute;
    bottom: 0;
    height: inherit;

    .progress {
      position: relative;
      height: 20px;
      z-index: auto;
    }
  }
}

.grilla-seleccionar {
  display: grid;
  grid-template-columns: 1fr;

  .elemento {
    display: grid;
    grid-template-columns: 1fr 15%;
    align-items: center;
    padding: 1rem;
    border-bottom: 2px solid #e2e2e2;

    .boton {
      button {
        background: #582D73;
        color: #fff;
        border: none;
        border-radius: 50%;
        padding: 5px 10px;
      }
    }

    &:last-child {
      border: none;
    }
  }

}
</style>
